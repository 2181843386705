import { useTranslation } from 'react-i18next';
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';
import DoneIcon from '@mui/icons-material/Done';
import { DelayedSlide } from '../common/delayed-slide';
import { Box, Button, Fab, TextField, Typography } from '@mui/material';
import OnCardError from '../common/error/on-card-error';
import { useContextSelector } from 'use-context-selector';
import { Types, UserContext } from '../../store/user/types';
import { userStore } from '../../store/user/user-store';
import { useEffect, useState } from 'react';
import { EnglishLevel } from '../../types/englishLevel';
import { MilitaryStatus } from '../../types/militaryStatus';
import { LegacyAPI } from '../../services/legacy-api/api';
import { WorkTimePreferencePayload } from '../../services/api-response/legacy-response';
import JobExperienceModal from './job-experience-modal';
import { JobExperience } from '../../types/jobExperience';
import { EducationInputForm } from './education-input-form';
import { DriverLicenceInputOptionsModal } from './driver-licence-input-options-modal';
import { DisplayedScreen } from '../../constants';

const englishLevelOptionList = [
  { key: EnglishLevel.ZERO, value: 'register.zero' },
  { key: EnglishLevel.LOW, value: 'register.low' },
  { key: EnglishLevel.NORMAL, value: 'register.normal' },
  { key: EnglishLevel.HIGH, value: 'register.high' },
];

const militaryStatusOptionList = [
  { key: MilitaryStatus.WAITING, value: 'register.waiting' },
  { key: MilitaryStatus.DELAYED, value: 'register.delayed' },
  { key: MilitaryStatus.DONE, value: 'register.done' },
];

const currentWorkStatusOptionList = [
  { key: true, value: 'register.working' },
  { key: false, value: 'register.notWorking' },
];

export interface FormConfig {
  [key: string]: {
    isActive: boolean;
    isRequired: boolean;
  };
}

export const WorkerOnboarding = ({
  displayed,
  setDisplayedIndex,
}: {
  displayed: boolean;
  setDisplayedIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { t } = useTranslation();
  const [englishLevel, setEnglishLevel] = useState<EnglishLevel | undefined>(undefined);
  const [militaryStatus, setMilitaryStatus] = useState<MilitaryStatus | undefined>(undefined);
  const [drivingLicences, setDrivingLicences] = useState<string[]>([]);
  const [selectedDrivingLicences, setSelectedDrivingLicences] = useState<string[]>([]);
  const [currentWorkStatus, setCurrentWorkStatus] = useState<boolean | undefined>(undefined);
  const [workTimePreferences, setWorkTimePreferences] = useState<WorkTimePreferencePayload[]>([]);
  const [expectedSalary, setExpectedSalary] = useState<number | undefined>(undefined);
  const [selectedWorkTimePreferences, setSelectedWorkTimePreferences] = useState<
    WorkTimePreferencePayload[]
  >([]);
  const [jobExperienceModalOpen, setJobExperienceModalOpen] = useState<boolean>(false);
  const [jobExperiences, setJobExperiences] = useState<JobExperience[]>([]);
  const [selectedJobExperience, setSelectedJobExperience] = useState<JobExperience | undefined>(
    undefined,
  );
  const [formConfig, setFormConfig] = useState<FormConfig | null>(null);
  const [driverLicenceInputOptionsModalOpen, setDriverLicenceInputOptionsModalOpen] =
    useState<boolean>(false);
  const [isSelectedForDriverLicenceIsNotExist, setIsSelectedForDriverLicenceIsNotExist] =
    useState(false);
  const [isSelectedForDriverLicenceIsExist, setIsSelectedForDriverLicenceIsExist] = useState(false);

  const dispatch = useContextSelector<UserContext, UserContext['dispatch']>(
    userStore,
    (s) => s.dispatch,
  );

  const personalInfosSubmitError = useContextSelector<
    UserContext,
    UserContext['state']['personalInfosSubmitError']
  >(userStore, (s) => s.state.personalInfosSubmitError);

  const showQuestions = useContextSelector<UserContext, UserContext['state']['showQuestions']>(
    userStore,
    (s) => s.state.showQuestions,
  );

  const userGender = useContextSelector<UserContext, UserContext['state']['gender']>(
    userStore,
    (s) => s.state.gender,
  );

  const accessToken = useContextSelector<UserContext, UserContext['state']['accessToken']>(
    userStore,
    (s) => s.state.accessToken,
  );

  const loading = useContextSelector<UserContext, UserContext['state']['loading']>(
    userStore,
    (s) => s.state.loading,
  );

  const api = new LegacyAPI();

  api.config.headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const getDrivingLicenses = async () => {
    const drivingLicencesResponse = await api.getDrivingLicenses();
    setDrivingLicences(drivingLicencesResponse);
  };

  const getWorkTimePreferences = async () => {
    const workTimePreferences = await api.getWorkTimePreferences();
    setWorkTimePreferences(workTimePreferences);
  };

  const getFormInputList = async () => {
    const formInputList = await api.getFormInputList('OnboardingForm');
    setFormConfig(formInputList);
  };

  const onEnglishLevelSelect = (englishLevelInput: EnglishLevel) => {
    if (englishLevel === englishLevelInput) {
      setEnglishLevel(undefined);
      dispatch({ type: Types.EnglishLevelSelect, payload: {} });
      return;
    }

    setEnglishLevel(englishLevelInput);
    dispatch({ type: Types.EnglishLevelSelect, payload: { englishLevel: englishLevelInput } });
  };

  const onMilitaryStatusSelect = (militaryStatusInput: MilitaryStatus) => {
    if (militaryStatus === militaryStatusInput) {
      setMilitaryStatus(undefined);
      dispatch({ type: Types.MilitaryStatusSelect, payload: {} });
      return;
    }

    setMilitaryStatus(militaryStatusInput);
    dispatch({
      type: Types.MilitaryStatusSelect,
      payload: { militaryStatus: militaryStatusInput },
    });
  };

  const onCurrentWorkStatusSelect = (currentWorkStatusInput: boolean) => {
    if (currentWorkStatus === currentWorkStatusInput) {
      setCurrentWorkStatus(undefined);
      dispatch({ type: Types.CurrentWorkStatusSelect, payload: {} });
      return;
    }

    setCurrentWorkStatus(currentWorkStatusInput);
    dispatch({
      type: Types.CurrentWorkStatusSelect,
      payload: { currentWorkStatus: currentWorkStatusInput },
    });
  };

  const onExpectedSalarySelect = (expectedSalaryInput: number) => {
    setExpectedSalary(expectedSalaryInput);
    dispatch({
      type: Types.ExpectedSalarySelect,
      payload: { expectedSalary: expectedSalaryInput },
    });
  };

  const onWorkTimePreferencesSelect = (workTimePreferenceInput: WorkTimePreferencePayload) => {
    const isExistWorkTimePreference = selectedWorkTimePreferences.find(
      (workTimePreference) => workTimePreference === workTimePreferenceInput,
    );

    if (isExistWorkTimePreference) {
      const selectedWorkTimePreferencesWithoutInput = selectedWorkTimePreferences.filter(
        (workTimePreference) => workTimePreference !== workTimePreferenceInput,
      );
      setSelectedWorkTimePreferences(selectedWorkTimePreferencesWithoutInput);
      dispatch({
        type: Types.WorkTimePreferencesSelect,
        payload: {
          workTimePreferences: selectedWorkTimePreferencesWithoutInput.map(
            (workTimePreference) => workTimePreference.id,
          ),
        },
      });
      return;
    }

    const updatedSelectedWorkTimePreferences = [
      ...selectedWorkTimePreferences,
      workTimePreferenceInput,
    ];
    setSelectedWorkTimePreferences(updatedSelectedWorkTimePreferences);
    dispatch({
      type: Types.WorkTimePreferencesSelect,
      payload: {
        workTimePreferences: updatedSelectedWorkTimePreferences.map(
          (workTimePreference) => workTimePreference.id,
        ),
      },
    });
  };

  const onDrivingLicencesSelect = (drivingLicenceInput: string) => {
    const isExistDrivingLicence = selectedDrivingLicences.find(
      (licence) => licence === drivingLicenceInput,
    );

    if (isExistDrivingLicence) {
      const selectedDrivingLicencesWithoutInput = selectedDrivingLicences.filter(
        (licence) => licence !== drivingLicenceInput,
      );
      setSelectedDrivingLicences(selectedDrivingLicencesWithoutInput);
      dispatch({
        type: Types.DrivingLicencesSelect,
        payload: { drivingLicences: selectedDrivingLicencesWithoutInput },
      });
      return;
    }

    let updatedSelectedDrivingLicences = [...selectedDrivingLicences, drivingLicenceInput];
    if (drivingLicenceInput === 'Ehliyetim Yok') {
      updatedSelectedDrivingLicences = ['Ehliyetim Yok'];
    } else if (
      drivingLicenceInput !== 'Ehliyetim Yok' &&
      updatedSelectedDrivingLicences.includes('Ehliyetim Yok')
    ) {
      updatedSelectedDrivingLicences = updatedSelectedDrivingLicences.filter(
        (licence) => licence !== 'Ehliyetim Yok',
      );
    }

    setSelectedDrivingLicences(updatedSelectedDrivingLicences);
    dispatch({
      type: Types.DrivingLicencesSelect,
      payload: { drivingLicences: updatedSelectedDrivingLicences },
    });
  };

  const onJobExperienceEdit = (jobExperienceInput: JobExperience) => {
    setSelectedJobExperience(jobExperienceInput);
    setJobExperienceModalOpen(true);
  };

  const handleSubmit = () => {
    if (formConfig) {
      dispatch({ type: Types.PersonalInfosSubmitted, payload: { formConfig } });
    }
  };

  const isFieldVisible = (fieldName: string): boolean => {
    if (!formConfig || !formConfig[fieldName]) {
      return true;
    }

    return formConfig[fieldName].isActive;
  };

  useEffect(() => {
    getDrivingLicenses();
    getWorkTimePreferences();
    getFormInputList();
  }, []);

  useEffect(() => {
    setIsSelectedForDriverLicenceIsNotExist(selectedDrivingLicences.includes('Ehliyetim Yok'));
    setIsSelectedForDriverLicenceIsExist(
      !selectedDrivingLicences.includes('Ehliyetim Yok') && selectedDrivingLicences.length > 0,
    );
  }, [selectedDrivingLicences]);

  useEffect(() => {
    if (showQuestions) {
      setDisplayedIndex(DisplayedScreen.Questions);
    }
  }, [showQuestions]);

  return (
    <DelayedSlide iN={displayed} delay={800}>
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex',
          alignItems: 'center',
        }}>
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '60%',
            },
          }}>
          <OnCardError error={personalInfosSubmitError} fromTop />

          <Typography gutterBottom variant="h5" component="div">
            {t('register.enterPersonalInfos')}
          </Typography>

          {isFieldVisible('experiences') && (
            <Box>
              <Typography gutterBottom variant="body1" display="block">
                {t('register.jobExperience')}
              </Typography>
              <Box
                sx={{
                  padding: '1rem',
                  display: 'flex',
                  gap: '1rem',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}>
                {jobExperiences.map((jobExperienceOption) => (
                  <Box
                    key={`${jobExperienceOption.company_name}-${jobExperienceOption.position}-box`}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      gap: '1rem',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}>
                    <Button
                      sx={{
                        color: 'darkgray',
                        border: 1,
                        borderColor: 'gray',
                        flex: 1,
                        fontWeight: 'normal',
                      }}>
                      {`${jobExperienceOption.company_name} (${jobExperienceOption.position})`}
                    </Button>
                    <Button
                      sx={{
                        color: 'darkgray',
                        border: 1,
                        borderColor: 'gray',
                        fontWeight: 'normal',
                      }}
                      onClick={() => onJobExperienceEdit(jobExperienceOption)}>
                      {t('register.edit')}
                    </Button>
                  </Box>
                ))}

                <Box
                  sx={{
                    display: 'flex',
                    gap: '1rem',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  {jobExperiences.length === 0 && (
                    <Typography
                      gutterBottom
                      variant="body1"
                      display="block"
                      sx={{ fontWeight: 'bold' }}>
                      {t('register.jobExperienceNotExist')}
                    </Typography>
                  )}
                  <Button
                    sx={{ color: 'darkgray', border: 1, borderColor: 'gray', fontWeight: 0 }}
                    onClick={() => {
                      setSelectedJobExperience(undefined);
                      setJobExperienceModalOpen(true);
                    }}>
                    {t('register.addJobExperience')}
                  </Button>
                </Box>
              </Box>
            </Box>
          )}

          {isFieldVisible('education') && <EducationInputForm dispatch={dispatch} />}

          {isFieldVisible('english_level') && (
            <Box>
              <Typography gutterBottom variant="body1" display="block">
                {t('register.englishLevel')}
              </Typography>
              <Box
                sx={{
                  padding: '1rem',
                  display: 'flex',
                  gap: '1rem',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                {englishLevelOptionList.map((option) => {
                  const isSelected = englishLevel === option.key;
                  return (
                    <Button
                      endIcon={isSelected && <DoneIcon />}
                      onClick={() => onEnglishLevelSelect(option.key)}
                      sx={{
                        color: isSelected ? 'white' : 'darkgray',
                        backgroundColor: isSelected ? 'darkcyan' : '',
                        opacity: isSelected ? 0.8 : 1,
                        border: isSelected ? 0 : 1,
                        borderColor: 'gray',
                        fontWeight: 'normal',
                        flex: '1 0 auto',
                      }}
                      key={option.key}>
                      {t(option.value)}
                    </Button>
                  );
                })}
              </Box>
            </Box>
          )}

          {isFieldVisible('military_status') && userGender === 'male' && (
            <Box>
              <Typography gutterBottom variant="body1" display="block">
                {t('register.militaryStatus')}
              </Typography>
              <Box
                sx={{
                  padding: '1rem',
                  display: 'flex',
                  gap: '1rem',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                {militaryStatusOptionList.map((option) => {
                  const isSelected = militaryStatus === option.key;
                  return (
                    <Button
                      endIcon={isSelected && <DoneIcon />}
                      onClick={() => onMilitaryStatusSelect(option.key)}
                      sx={{
                        color: isSelected ? 'white' : 'darkgray',
                        backgroundColor: isSelected ? 'darkcyan' : '',
                        opacity: isSelected ? 0.8 : 1,
                        border: isSelected ? 0 : 1,
                        borderColor: 'gray',
                        fontWeight: 'normal',
                        flex: '1 0 auto',
                      }}
                      key={option.key}>
                      {t(option.value)}
                    </Button>
                  );
                })}
              </Box>
            </Box>
          )}

          {isFieldVisible('drivingLicences') && (
            <Box>
              <Typography gutterBottom variant="body1" display="block">
                {t('register.drivingLicence')}
              </Typography>
              <Box
                sx={{
                  padding: '1rem',
                  display: 'flex',
                  gap: '1rem',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Button
                  endIcon={isSelectedForDriverLicenceIsNotExist && <DoneIcon />}
                  onClick={() => onDrivingLicencesSelect('Ehliyetim Yok')}
                  sx={{
                    color: isSelectedForDriverLicenceIsNotExist ? 'white' : 'darkgray',
                    backgroundColor: isSelectedForDriverLicenceIsNotExist ? 'darkcyan' : '',
                    opacity: isSelectedForDriverLicenceIsNotExist ? 0.8 : 1,
                    border: isSelectedForDriverLicenceIsNotExist ? 0 : 1,
                    fontWeight: 'normal',
                    borderColor: 'gray',
                    whiteSpace: 'nowrap',
                    flex: '1 0 auto',
                  }}
                  key={'register.driverLicenceNotExist'}>
                  {t('register.driverLicenceNotExist')}
                </Button>
                <Button
                  endIcon={isSelectedForDriverLicenceIsExist && <DoneIcon />}
                  onClick={() => setDriverLicenceInputOptionsModalOpen(true)}
                  sx={{
                    color: isSelectedForDriverLicenceIsExist ? 'white' : 'darkgray',
                    backgroundColor: isSelectedForDriverLicenceIsExist ? 'darkcyan' : '',
                    opacity: isSelectedForDriverLicenceIsExist ? 0.8 : 1,
                    border: isSelectedForDriverLicenceIsExist ? 0 : 1,
                    fontWeight: 'normal',
                    borderColor: 'gray',
                    whiteSpace: 'nowrap',
                    flex: '1 0 auto',
                  }}
                  key={'register.driverLicenseExist'}>
                  {t('register.driverLicenseExist')}
                </Button>
              </Box>
            </Box>
          )}

          {isFieldVisible('currentWorkStatus') && (
            <Box>
              <Typography gutterBottom variant="body1" display="block">
                {t('register.currentWorkStatus')}
              </Typography>
              <Box
                sx={{
                  padding: '1rem',
                  display: 'flex',
                  gap: '1rem',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                {currentWorkStatusOptionList.map((option) => {
                  const isSelected = currentWorkStatus === option.key;
                  return (
                    <Button
                      endIcon={isSelected && <DoneIcon />}
                      onClick={() => onCurrentWorkStatusSelect(option.key)}
                      sx={{
                        color: isSelected ? 'white' : 'darkgray',
                        backgroundColor: isSelected ? 'darkcyan' : '',
                        opacity: isSelected ? 0.8 : 1,
                        border: isSelected ? 0 : 1,
                        borderColor: 'gray',
                        fontWeight: 'normal',
                        flex: '1 0 auto',
                      }}
                      key={`currentWorkOption-${option.key}`}>
                      {t(option.value)}
                    </Button>
                  );
                })}
              </Box>
            </Box>
          )}

          {isFieldVisible('workTimePreferences') && (
            <Box>
              <Typography gutterBottom variant="body1" display="block">
                {t('register.workTimePreferences')}
              </Typography>
              <Box
                sx={{
                  padding: '1rem',
                  display: 'flex',
                  gap: '1rem',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                {workTimePreferences.map((option) => {
                  const isSelected = selectedWorkTimePreferences.includes(option);
                  return (
                    <Button
                      endIcon={isSelected && <DoneIcon />}
                      onClick={() => onWorkTimePreferencesSelect(option)}
                      sx={{
                        color: isSelected ? 'white' : 'darkgray',
                        backgroundColor: isSelected ? 'darkcyan' : '',
                        opacity: isSelected ? 0.8 : 1,
                        border: isSelected ? 0 : 1,
                        borderColor: 'gray',
                        whiteSpace: 'nowrap',
                        fontWeight: 'normal',
                        flex: '1 0 auto',
                      }}
                      key={option.id}>
                      {t(option.translate_key)}
                    </Button>
                  );
                })}
              </Box>
            </Box>
          )}

          {isFieldVisible('expectedSalary') && (
            <Box>
              <Typography gutterBottom variant="body1" display="block">
                {t('register.expectedSalary')}
              </Typography>
              <TextField
                placeholder={t('register.enterExpectedSalary') ?? ''}
                value={expectedSalary}
                sx={{ minWidth: '250px' }}
                onChange={(e) => {
                  onExpectedSalarySelect(+e.target.value);
                }}
                type="number"
                variant="standard"
              />
            </Box>
          )}

          <Box
            sx={{
              margin: '2rem',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Fab variant="extended" color="primary" onClick={handleSubmit} disabled={loading}>
              {loading ? (
                <div>
                  <span>{t('common.loading')}</span>
                </div>
              ) : (
                <>
                  <ArrowForwardIosTwoToneIcon sx={{ mr: 1 }} />
                  <span>{t('phoneEmail.continue')}</span>
                </>
              )}
            </Fab>
          </Box>
        </Box>

        <JobExperienceModal
          modalOpen={jobExperienceModalOpen}
          setModalOpen={setJobExperienceModalOpen}
          jobExperiences={jobExperiences}
          setJobExperiences={setJobExperiences}
          jobExperience={selectedJobExperience}
          setJobExperience={setSelectedJobExperience}
          api={api}
        />
        <DriverLicenceInputOptionsModal
          modalOpen={driverLicenceInputOptionsModalOpen}
          setModalOpen={setDriverLicenceInputOptionsModalOpen}
          selectedDrivingLicences={selectedDrivingLicences}
          onDrivingLicencesSelect={onDrivingLicencesSelect}
          drivingLicences={drivingLicences}
        />
      </Box>
    </DelayedSlide>
  );
};
