import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { LEGACY_API_BASE_URL } from '../../constants';
import { Job } from '../../types/job';
import {
  ApplyToJobResponse,
  EmailCheckResponse,
  GetJobIdResponse,
  GetJobResponse,
  JobApplicationPayload,
  JobPositionResponse,
  LegacyAPIResponse,
  PhoneCheckResponse,
  RegisterUserResponse,
  VerificationCodeResponse,
  WorkTimePreferencePayload,
  WorkTimePreferenceResponse,
} from '../api-response/legacy-response';
import {
  EmailResponseMessage,
  PhoneResponseMessage,
  VerificationCodeResponseMessage,
} from '../api-response/legacy-response/types';
import { checkIfAxiosErrorAndUnauthenticatedAndThrowError } from '../common';
import axiosInstance from '../axios';
import { MomentDate } from '../../types/appointment-slot';
import { Gender } from '../../types/gender';
import { JobPosition } from '../../types/job-position';
import { JobExperience, JobExperienceRequest } from '../../types/jobExperience';
import { getLocale } from '../../utils/common';
import { ProfileUpdateRequest } from '../../types/profileUpdateRequest';

export class LegacyAPI {
  legacyServiceRoute = LEGACY_API_BASE_URL;
  config: AxiosRequestConfig = {};

  constructor() {
    this.config = { withCredentials: true };
  }
  getJobWithUUID = async (uuid: string): Promise<Job> => {
    try {
      const result = await axiosInstance.get<GetJobResponse>(
        `${this.legacyServiceRoute}/public/job-detail/${uuid}`,
        this.config,
      );

      if (result.status === 200) {
        const loadedJob = result.data.payload as Job;
        const latLon = loadedJob.location?.split(',');

        if (latLon) {
          return {
            ...loadedJob,
            lat: parseFloat(latLon[0]),
            lng: parseFloat(latLon[1]),
          };
        }

        return loadedJob;
      }
    } catch (e) {
      checkIfAxiosErrorAndUnauthenticatedAndThrowError(e, 'Legacy');

      const error = e as AxiosError<GetJobResponse>;

      if (error.response?.status === 404) {
        throw new Error('apiResponse.couldNotGetJob');
      }

      throw new Error((error.response?.data as LegacyAPIResponse).payload.message);
    }

    throw new Error('apiResponse.couldNotGetJob');
  };

  checkPhone = async (
    phone: string,
    country: string,
  ): Promise<Omit<PhoneCheckResponse, 'status'>> => {
    try {
      const result = await axiosInstance.post<PhoneCheckResponse>(
        `${this.legacyServiceRoute}/public/phone-check`,
        {
          countryCode: country,
          phoneNumber: phone,
        },
      );

      if (result.status === 200) {
        return result.data;
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const error = e as AxiosError<PhoneCheckResponse>;

        if (
          error.response &&
          error.response.data.payload.message === PhoneResponseMessage.NOT_WORKER
        ) {
          return error.response.data;
        }

        if (
          error.response &&
          error.response.data.payload.message === PhoneResponseMessage.VERIFIED_PHONE_NOT_FOUND
        ) {
          return error.response.data;
        }

        throw new Error(error.response?.data.payload.message);
      }
    }

    throw new Error('common.somethingWentWrong');
  };

  checkEmail = async (
    email: string,
    phoneNumber: string,
    country: string,
  ): Promise<Omit<EmailCheckResponse, 'status'>> => {
    try {
      const result = await axiosInstance.post<EmailCheckResponse>(
        `${this.legacyServiceRoute}/public/email-check`,
        {
          email,
          phoneNumber,
          countryCode: country,
        },
      );

      if (result.status === 200) {
        return result.data;
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const error = e as AxiosError<EmailCheckResponse>;

        if (
          error.response &&
          error.response.data.payload.message === EmailResponseMessage.EMAIL_IN_USE
        ) {
          return error.response.data;
        }

        throw new Error(error.response?.data.payload.message);
      }
    }

    throw new Error('common.somethingWentWrong');
  };

  submitVerificationCode = async (
    code: string,
    phone: string,
    country: string,
    email?: string,
  ): Promise<Omit<VerificationCodeResponse, 'status'>> => {
    try {
      const result = await axiosInstance.put<VerificationCodeResponse>(
        `${this.legacyServiceRoute}/v2/public/verify-phone`,
        {
          code,
          email,
          phoneNumber: phone,
          countryCode: country,
        },
      );

      if (result.status === 200) {
        return result.data;
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const error = e as AxiosError<VerificationCodeResponse>;

        if (
          error.response &&
          error.response.data.payload.message === VerificationCodeResponseMessage.ERROR
        ) {
          return error.response.data;
        }

        throw new Error(error.response?.data.payload.message);
      }
    }

    throw new Error('common.somethingWentWrong');
  };

  registerUser = async (
    email: string,
    phone: string,
    country: string,
    firstName: string,
    lastName: string,
    phoneVerifyCode: string,
    gender: Gender,
    birthDate: MomentDate,
    latLon: { lat: number; lon: number },
    geolocation?: string,
  ): Promise<Omit<RegisterUserResponse, 'status'>> => {
    try {
      const result = await axiosInstance.post<RegisterUserResponse>(
        `${this.legacyServiceRoute}/public/worker/register`,
        {
          email,
          phone,
          countryCode: country,
          firstName,
          lastName,
          phoneVerifyCode,
          birthDate: birthDate.toISOString(),
          gender,
          location: `${latLon.lat}, ${latLon.lon}`,
          geoLocation: geolocation,
        },
      );

      if (result.status === 200) {
        return result.data;
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const error = e as AxiosError<RegisterUserResponse>;

        if (
          error.response &&
          error.response.data.payload.message === VerificationCodeResponseMessage.ERROR
        ) {
          return error.response.data;
        }

        throw new Error(error.response?.data.payload.message);
      }
    }

    throw new Error('common.somethingWentWrong');
  };

  getJobIdWithUUID = async (uuid: string): Promise<number> => {
    try {
      const result = await axiosInstance.get<GetJobIdResponse>(
        `${this.legacyServiceRoute}/job/get/${uuid}`,
        {
          ...this.config,
        },
      );
      if (result.status === 200) {
        return result.data.payload.id;
      }
    } catch (e) {
      checkIfAxiosErrorAndUnauthenticatedAndThrowError(e, 'Legacy');

      const error = e as AxiosError<GetJobIdResponse>;

      throw new Error(error.response?.data.payload.message ?? 'common.somethingWentWrong');
    }

    throw new Error('apiResponse.couldNotGetJob');
  };

  applyToJob = async (jobId: number): Promise<JobApplicationPayload> => {
    try {
      const result = await axiosInstance.put<ApplyToJobResponse>(
        `${this.legacyServiceRoute}/v2/application/create/${jobId}`,
        {},
        this.config,
      );

      return result.data.payload.application;
    } catch (e) {
      checkIfAxiosErrorAndUnauthenticatedAndThrowError(e, 'Legacy');

      const error = e as AxiosError<ApplyToJobResponse>;

      throw new Error(error.response?.data.message ?? 'common.somethingWentWrong');
    }
  };

  getDrivingLicenses = async (): Promise<string[]> => {
    try {
      const result = await axiosInstance.get<any>(`${this.legacyServiceRoute}/driving-licenses`);

      return result.data.payload.drivingLicenses;
    } catch (e) {
      throw new Error('apiResponse.coulNotGetDrivingLicenses');
    }
  };

  getWorkTimePreferences = async (): Promise<WorkTimePreferencePayload[]> => {
    try {
      const result = await axiosInstance.get<WorkTimePreferenceResponse>(
        `${this.legacyServiceRoute}/work-time-preference`,
        this.config,
      );

      return result.data.payload;
    } catch (e) {
      throw new Error('apiResponse.couldNotGetWorkTimePreferences');
    }
  };

  getJobPositions = async (searchQuery: string): Promise<JobPosition[]> => {
    try {
      const result = await axiosInstance.get<JobPositionResponse>(
        `${this.legacyServiceRoute}/job-positions`,
        {
          params: {
            q: searchQuery ? searchQuery : '*',
          },
          headers: this.config.headers,
        },
      );

      return result.data.payload.positions;
    } catch (e) {
      throw new Error('apiResponse.couldNotGetJobPositions');
    }
  };

  addJobExperience = async (jobExperience: JobExperienceRequest): Promise<JobExperience> => {
    try {
      const result = await axiosInstance.put<any>(
        `${this.legacyServiceRoute}/worker/experience/create`,
        { ...jobExperience },
        this.config,
      );

      return result.data.payload.user_experience;
    } catch (e) {
      checkIfAxiosErrorAndUnauthenticatedAndThrowError(e, 'Legacy');

      throw new Error('common.somethingWentWrong');
    }
  };

  updateJobExperience = async (id: number, jobExperience: any): Promise<JobExperience> => {
    try {
      const result = await axiosInstance.post<any>(
        `${this.legacyServiceRoute}/worker/experience/update/${id}`,
        { ...jobExperience },
        this.config,
      );

      return result.data.payload.user_experience;
    } catch (e) {
      checkIfAxiosErrorAndUnauthenticatedAndThrowError(e, 'Legacy');

      throw new Error('common.somethingWentWrong');
    }
  };

  deleteJobExperience = async (id: number): Promise<void> => {
    try {
      await axiosInstance.get<any>(
        `${this.legacyServiceRoute}/worker/experience/delete/${id}`,
        this.config,
      );
    } catch (e) {
      throw new Error('apiResponse.couldNotDeleteJobExperience');
    }
  };

  getFormInputList = async (name: string): Promise<any> => {
    try {
      const result = await axiosInstance.post<any>(
        `${this.legacyServiceRoute}/form-input-list`,
        {
          formName: name,
        },
        {
          headers: {
            'X-App-Info': 'v11 web 11',
            'X-App-Country': getLocale(),
          },
        },
      );

      return result.data.payload;
    } catch (e) {
      throw new Error('apiResponse.couldNotGetFormInputList');
    }
  };

  updateEducation = async (educationStatus?: string): Promise<boolean> => {
    try {
      const result = await axiosInstance.post<any>(
        `${this.legacyServiceRoute}/profile-update-education`,
        { education_status: educationStatus },
        this.config,
      );

      return result.data.payload.code === 200;
    } catch (e) {
      checkIfAxiosErrorAndUnauthenticatedAndThrowError(e, 'Legacy');

      throw new Error('apiResponse.couldNotUpdateEducation');
    }
  };

  updateWorkTimePreferences = async (workTimePreferences?: number[]): Promise<boolean> => {
    try {
      const result = await axiosInstance.post<any>(
        `${this.legacyServiceRoute}/worker/update-work-preferences`,
        { work_time_preferences: workTimePreferences },
        this.config,
      );

      return result.data.payload.code === 200;
    } catch (e) {
      checkIfAxiosErrorAndUnauthenticatedAndThrowError(e, 'Legacy');

      throw new Error('apiResponse.couldNotUpdateWorkTimePreferences');
    }
  };

  updateCurrentWorkStatus = async (currentWorkStatus?: boolean): Promise<boolean> => {
    try {
      const result = await axiosInstance.post<any>(
        `${this.legacyServiceRoute}/worker/update-current-work-status`,
        { unemployed_status: !currentWorkStatus },
        this.config,
      );

      return result.data.payload.code === 200;
    } catch (e) {
      checkIfAxiosErrorAndUnauthenticatedAndThrowError(e, 'Legacy');

      throw new Error('apiResponse.couldNotUpdateCurrentWorkStatus');
    }
  };

  updateDrivingLicences = async (drivingLicences?: string[]): Promise<boolean> => {
    try {
      const result = await axiosInstance.put<any>(
        `${this.legacyServiceRoute}/update-driving-licenses`,
        { drivingLicences },
        this.config,
      );

      return result.data.payload.code === 200;
    } catch (e) {
      checkIfAxiosErrorAndUnauthenticatedAndThrowError(e, 'Legacy');

      throw new Error('apiResponse.couldNotUpdateDrivingLicences');
    }
  };

  updateProfile = async (profileUpdateRequest: ProfileUpdateRequest): Promise<boolean> => {
    try {
      const result = await axiosInstance.post<any>(
        `${this.legacyServiceRoute}/profile-update`,
        { ...profileUpdateRequest },
        this.config,
      );

      return result.data.payload.code === 200;
    } catch (e) {
      checkIfAxiosErrorAndUnauthenticatedAndThrowError(e, 'Legacy');

      throw new Error('apiResponse.couldNotUpdateProfile');
    }
  };
}
