import { CircularProgress } from '@mui/material';
import { CenteredComponent } from './centered-component';

export const LoadingSpinner = ({ size }: { size?: number }) => {
  return (
    <CenteredComponent>
      <CircularProgress color="secondary" size={size ?? 40} />
    </CenteredComponent>
  );
};
