import { Alert, SxProps, Theme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const baseStyle: SxProps<Theme> = {
  marginTop: '1rem',
};

const OnCardError = ({ error, fromTop }: { error?: string; fromTop?: boolean }) => {
  const { t } = useTranslation();
  const style: SxProps<Theme> = fromTop
    ? {
        ...baseStyle,
        position: 'fixed',
        top: '5vh',
        left: '50vw',
        transform: 'translate(-50%, 0)',
        marginTop: '1rem',
      }
    : baseStyle;

  if (!error) {
    return <></>;
  }

  return (
    <Alert sx={style} severity="error">
      {t(error)}
    </Alert>
  );
};

export default OnCardError;
