import { Box, Input } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
const DIGIT_ARRAY = Array.from(Array(4).keys());

export const SMSVerifyInput = ({
  setVerificationCode,
  autoFocus,
}: {
  setVerificationCode: (code: string) => void;
  autoFocus?: boolean;
}) => {
  const [focusedDigit, setFocusedDigit] = useState(0);
  const [code, setCode] = useState('');
  const itemsRef = useRef<Array<HTMLInputElement | null>>([]);

  const handleDigitChange = (digit: number, newValue: string) => {
    if (newValue === '') {
      const codeToSet = code.replace(code[digit], '');
      setFocusedDigit(digit - 1);
      setCode(codeToSet);
      setVerificationCode(codeToSet);
      return;
    }

    const regex = /^[0-9\b]+$/;

    if (regex.test(newValue)) {
      const newCode = code.split('');
      newCode[digit] = newValue;
      const codeToSet = newCode.join('');
      setCode(codeToSet);
      setVerificationCode(codeToSet);
      setFocusedDigit(digit + 1);
    }
  };

  useEffect(() => {
    if (itemsRef.current[focusedDigit]) {
      itemsRef.current[focusedDigit]?.focus();
    }
  }, [focusedDigit]);

  useEffect(() => {
    if (!autoFocus) {
      itemsRef.current[0]?.blur();
    }
  }, [autoFocus]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      {DIGIT_ARRAY.map((digit) => (
        <Box
          key={digit}
          sx={{
            border: '1px solid #757575',
            borderRadius: '0.5rem',
            height: '4rem',
            width: '3rem',
            margin: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Input
            key={`input${digit}`}
            disableUnderline
            autoFocus={digit === 0 && autoFocus}
            inputProps={{
              maxLength: 1,
            }}
            type="tel"
            disabled={code.length >= digit + 2}
            inputRef={(element: HTMLInputElement) => (itemsRef.current[digit] = element)}
            value={code.length > digit ? '*' : ''}
            onChange={(e) => handleDigitChange(digit, e.target.value)}
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#000000',
              },
              paddingLeft: '0.3rem',
              fontSize: '2rem',
              height: '100%',
              width: '50%',
              textAlign: 'center',
            }}
          />
        </Box>
      ))}
    </Box>
  );
};
