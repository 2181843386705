import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'autosuggest-highlight/parse';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GeolocationUtils from '../../utils/geolocation';
import { useScript } from '@uidotdev/usehooks';
import { GOOGLE_MAPS_KEY } from '../../constants';

type Suggestion = google.maps.places.AutocompletePrediction;

export const GooglePlacesAutoComplete = ({
  countryCode,
  setLatLon,
  initialLocation,
  languageCode,
  setUserGeolocation,
}: {
  countryCode: string;
  setLatLon: (lat: number, lon: number) => void;
  initialLocation?: { lat: number; lon: number };
  languageCode: string;
  setUserGeolocation: (geolocation: string) => void;
}) => {
  const { t } = useTranslation();
  const [selectedPrediction, setSelectedPrediction] = useState<Suggestion | null>(null);
  const [options, setOptions] = useState<Suggestion[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const status = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_KEY}&libraries=places`,
    {
      removeOnUnmount: false,
    },
  );

  const {
    ready,
    suggestions: { data },
    setValue,
    clearSuggestions,
    init,
  } = usePlacesAutocomplete({
    debounce: 300,
    initOnMount: false,
    requestOptions: {
      region: countryCode,
    },
  });

  useEffect(() => {
    if (data) {
      setOptions(data);
    }
  }, [data]);

  useEffect(() => {
    const isReady = status === 'ready';
    if (isReady) {
      init();
    }
  }, [status]);

  useEffect(() => {
    if (initialLocation) {
      setLatLon(initialLocation.lat, initialLocation.lon);
      GeolocationUtils.reverseGeoLocation(
        `${initialLocation.lat},${initialLocation.lon}`,
        languageCode,
      ).then((geolocationObject) => {
        const displayedValue = GeolocationUtils.convertGeoLocationToString(geolocationObject);
        setUserGeolocation(geolocationObject);
        setInputValue(displayedValue);
      });
    }
  }, [initialLocation]);

  return (
    <Autocomplete
      id="google-map-autocomplete"
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={data ?? Array<Suggestion>()}
      freeSolo
      autoComplete
      includeInputInList
      filterSelectedOptions
      disabled={!ready || initialLocation !== undefined}
      value={selectedPrediction}
      noOptionsText={t('common.noOptions')}
      onChange={async (event: unknown, newValue: Suggestion | null | string) => {
        if (typeof newValue === 'string') {
          return;
        }
        setOptions(newValue ? [newValue, ...options] : options);
        setSelectedPrediction(newValue);
        await getGeocode({ address: newValue?.description }).then((results) => {
          const { lat, lng } = getLatLng(results[0]);
          setLatLon(lat, lng);
        });
        clearSuggestions();
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setValue(newInputValue);
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={t('common.locationSearch')} fullWidth variant="standard" />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};
