export const API_BASE_URL = `${process.env.REACT_APP_MICROSERVICES_BASE_URL}`;
export const LEGACY_API_BASE_URL = `${process.env.REACT_APP_MICROSERVICES_BASE_URL}/api`;
export const SENTRY_DSN = `${process.env.REACT_APP_SENTRY_DSN}`;
export const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const APP_ENV = process.env.REACT_APP_APP_ENVIRONMENT;

export enum DisplayedScreen {
  JobDetail = 0,
  PhoneInput = 1,
  EmailInput = 2,
  VerificationCodeInput = 3,
  Register = 4,
  Questions = 5,
  Finished = 6,
  Sorry = 7,
  AppointmentSlots = 8,
  WorkerOnboarding = 9,
}
