import { Box, Typography, CircularProgress } from '@mui/material';
import { SMSVerifyInput } from './sms-verify-input';
import { useContextSelector } from 'use-context-selector';
import { Types, UserContext } from '../../store/user/types';
import { userStore } from '../../store/user/user-store';
import { useTranslation } from 'react-i18next';
import { DelayedSlide } from '../common/delayed-slide';
import { useEffect } from 'react';
import { DisplayedScreen } from '../../constants';
import OnCardError from '../common/error/on-card-error';

export const SMSVerifyCard = ({
  displayed,
  setDisplayedIndex,
}: {
  displayed: boolean;
  setDisplayedIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { t } = useTranslation();

  const dispatch = useContextSelector<UserContext, UserContext['dispatch']>(
    userStore,
    (s) => s.dispatch,
  );

  const phone = useContextSelector<UserContext, UserContext['state']['phoneNumber']>(
    userStore,
    (s) => s.state.phoneNumber,
  );

  const loading = useContextSelector<UserContext, UserContext['state']['loading']>(
    userStore,
    (s) => s.state.loading,
  );

  const verificationCode = useContextSelector<
    UserContext,
    UserContext['state']['verificationCode']
  >(userStore, (s) => s.state.verificationCode);

  const verificationError = useContextSelector<
    UserContext,
    UserContext['state']['verificationError']
  >(userStore, (s) => s.state.verificationError);

  const showQuestions = useContextSelector<UserContext, UserContext['state']['showQuestions']>(
    userStore,
    (s) => s.state.showQuestions,
  );

  const showRegister = useContextSelector<UserContext, UserContext['state']['showRegister']>(
    userStore,
    (s) => s.state.showRegister,
  );

  const registerCodeError = useContextSelector<
    UserContext,
    UserContext['state']['registerCodeError']
  >(userStore, (s) => s.state.registerCodeError);

  const onCodeChange = (code: string) => {
    dispatch({ type: Types.SMSCodeEntered, payload: { code } });
  };

  const handleSubmit = () => {
    dispatch({ type: Types.SMSCodeSubmitted, payload: {} });
  };

  useEffect(() => {
    if (showQuestions) {
      setDisplayedIndex(DisplayedScreen.Questions);
    }
  }, [showQuestions]);

  useEffect(() => {
    if (showRegister) {
      setDisplayedIndex(DisplayedScreen.Register);
    }
  }, [showRegister]);

  useEffect(() => {
    if (verificationCode && verificationCode.length === 4) {
      handleSubmit();
    }
  }, [verificationCode]);

  return (
    <DelayedSlide iN={displayed} delay={800}>
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: {
            xs: '1vh',
            sm: '5vh',
          },
        }}>
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '60%',
            },
          }}>
          {verificationError && <OnCardError error={verificationError} fromTop />}
          {registerCodeError && <OnCardError error={registerCodeError} fromTop />}

          <Typography gutterBottom variant="h5" component="div">
            {t('smsVerify.pleaseVerifyYourPhone', {
              phoneNumber: '*'.repeat(phone.length) + phone.slice(-4),
            })}
          </Typography>
          <SMSVerifyInput
            setVerificationCode={onCodeChange}
            autoFocus={!verificationError && !registerCodeError}
          />
          {loading && (
            <Box
              sx={{
                margin: '1rem',
                display: 'flex',
                justifyContent: 'center',
              }}>
              <CircularProgress color="secondary" size={40} />
            </Box>
          )}
        </Box>
      </Box>
    </DelayedSlide>
  );
};
