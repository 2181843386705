import { Box, Button, Fab, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import { Dispatch, useEffect, useRef, useState } from 'react';
import { Education } from '../../types/education';
import { Types, UserAction } from '../../store/user/types';

const educationOptionList = [
  { key: Education.ELEMENTARY_SCHOOL, value: 'register.elementarySchool' },
  { key: Education.MIDDLE_SCHOOL, value: 'register.middleSchool' },
  { key: Education.HIGH_SCHOOL, value: 'register.highSchool' },
  { key: Education.UNIVERSITY, value: 'register.university' },
];

export const EducationInputForm = ({ dispatch }: { dispatch: Dispatch<UserAction> }) => {
  const { t } = useTranslation();
  const [education, setEducation] = useState<Education | undefined>();
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerScrollLeft, setContainerScrollLeft] = useState(0);
  const [showLeftScrollButton, setShowLeftScrollButton] = useState(false);
  const [showRightScrollButton, setShowRightScrollButton] = useState(true);

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 200;
      setContainerScrollLeft(containerRef.current.scrollLeft);
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 200;
      setContainerScrollLeft(containerRef.current.scrollLeft);
    }
  };

  const onEducationSelect = (educationInput: Education) => {
    if (education === educationInput) {
      setEducation(undefined);
      dispatch({ type: Types.EducationSelect, payload: {} });
      return;
    }

    setEducation(educationInput);
    dispatch({ type: Types.EducationSelect, payload: { education: educationInput } });
  };

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    setShowLeftScrollButton(containerScrollLeft > 0);

    const scrollValueForRightButton =
      containerRef.current.scrollWidth - (containerRef.current.offsetWidth + containerScrollLeft);
    setShowRightScrollButton(scrollValueForRightButton > 0);
  }, [containerScrollLeft]);

  return (
    <Box>
      <Typography gutterBottom variant="body1" display="block">
        {t('register.education')}
      </Typography>
      <Box
        ref={containerRef}
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          overflowX: 'hidden',
          padding: '1rem',
          display: 'flex',
          gap: '1rem',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}>
        {showLeftScrollButton && (
          <Fab
            size="small"
            onClick={scrollLeft}
            variant="extended"
            color="primary"
            sx={{
              position: 'sticky',
              left: '0',
              zIndex: 1,
              opacity: 0.8,
            }}>
            <ArrowBackIosIcon />
          </Fab>
        )}

        <div style={{ flex: '0 0 auto', display: 'flex', gap: '1rem' }}>
          {educationOptionList.map((option) => {
            const isSelected = education === option.key;
            return (
              <Button
                endIcon={isSelected && <DoneIcon />}
                onClick={() => onEducationSelect(option.key)}
                sx={{
                  color: isSelected ? 'white' : 'darkgray',
                  backgroundColor: isSelected ? 'darkcyan' : '',
                  opacity: isSelected ? 0.8 : 1,
                  border: isSelected ? 0 : 1,
                  borderColor: 'gray',
                  flex: '0 0 auto',
                }}
                key={option.key}>
                {t(option.value)}
              </Button>
            );
          })}
        </div>

        {showRightScrollButton && (
          <Fab
            size="small"
            onClick={scrollRight}
            variant="extended"
            color="primary"
            sx={{
              position: 'sticky',
              right: '0',
              zIndex: 1,
              opacity: 0.8,
            }}>
            <ArrowForwardIosIcon />
          </Fab>
        )}
      </Box>
    </Box>
  );
};
