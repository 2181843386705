import { AxiosRequestConfig } from 'axios';
import axiosInstance from '../axios';
import { API_BASE_URL } from '../../constants';
import { checkIfAxiosErrorAndUnauthenticatedAndThrowError, ServiceResponse } from '../common';
import { GET_APPOINTMENT_SLOTS_ENDPOINT, CREATE_APPOINTMENT_ENDPOINT } from './constants';
import { AppointmentSlotPayload } from '../api-response/legacy-response';
import { CreateAppointmentPayload } from './types';

export class AppointmentAPI {
  appointmentServiceRoute = API_BASE_URL;
  config: AxiosRequestConfig = {};

  constructor() {
    this.config = { withCredentials: true };
  }

  getSuitableAppointmentSlots = async (
    jobId: number,
    workerId: number,
    companyId: number,
  ): Promise<AppointmentSlotPayload> => {
    const endpoint = GET_APPOINTMENT_SLOTS_ENDPOINT(jobId, workerId, companyId);

    try {
      const result = await axiosInstance.get<ServiceResponse<AppointmentSlotPayload>>(
        `${this.appointmentServiceRoute}/${endpoint}`,
        {
          ...this.config,
        },
      );

      if (result.data.code === 200) {
        return result.data.payload;
      }
    } catch (e) {
      checkIfAxiosErrorAndUnauthenticatedAndThrowError(e, 'Appointment');
    }

    throw new Error('common.somethingWentWrong');
  };

  createApplicationAppointment = async (data: CreateAppointmentPayload): Promise<void> => {
    const endpoint = CREATE_APPOINTMENT_ENDPOINT;

    try {
      const result = await axiosInstance.post<ServiceResponse<void>>(
        `${this.appointmentServiceRoute}/${endpoint}`,
        data,
        {
          ...this.config,
        },
      );

      if ([200, 201].includes(result.data.code)) {
        return;
      }
    } catch (e) {
      checkIfAxiosErrorAndUnauthenticatedAndThrowError(e, 'Appointment');
    }

    throw new Error('common.somethingWentWrong');
  };
}
