import { Box, TextField } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useState } from 'react';

export const MuiFullname = ({
  onFirstNameChange,
  onLastNameChange,
}: {
  onFirstNameChange: (name: string) => void;
  onLastNameChange: (surname: string) => void;
}) => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <TextField
        fullWidth
        sx={{ marginY: '2rem' }}
        label={t('common.firstName')}
        value={firstName}
        onChange={(e) => {
          setFirstName(e.target.value);
          onFirstNameChange(e.target.value);
        }}
        type="text"
        autoFocus
        variant="standard"
      />

      <TextField
        fullWidth
        sx={{ marginY: '2rem', marginLeft: '2rem' }}
        label={t('common.lastName')}
        value={lastName}
        onChange={(e) => {
          setLastName(e.target.value);
          onLastNameChange(e.target.value);
        }}
        type="text"
        variant="standard"
      />
    </Box>
  );
};
