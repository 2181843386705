import { FormConfig } from '../../components/worker-onboarding/worker-onboarding';
import { isValidEmail } from '../../utils/common';
import { CountryCode, isPossiblePhoneNumber } from 'libphonenumber-js';

export const validatePhoneInput = (
  phoneNumber: string,
  country: string,
): { phoneError?: string } => {
  let phoneError;

  if (!phoneNumber) {
    phoneError = 'common.phoneNumberRequired';
  }

  if (!country) {
    phoneError = 'common.countryRequired';
  }
  if (phoneNumber && country && !isPossiblePhoneNumber(phoneNumber, country as CountryCode)) {
    phoneError = 'common.invalidPhoneNumber';
  }

  return { phoneError };
};

export const validateEmailInput = (email: string): { emailError?: string } => {
  let emailError;
  if (!email) {
    emailError = 'common.emailRequired';
  }
  if (email && !isValidEmail(email)) {
    emailError = 'common.invalidEmail';
  }
  return { emailError };
};

export const validateFullnameInput = (
  firstName: string,
  lastName: string,
): { fullnameError?: string } => {
  let fullnameError;

  if (!firstName || firstName.trim() === '') {
    fullnameError = 'common.firstnameRequired';
  }

  if (!lastName || lastName.trim() === '') {
    fullnameError = 'common.lastnameRequired';
  }
  return { fullnameError };
};

export const isFieldRequired = (formConfig: FormConfig, fieldName: string): boolean => {
  if (!formConfig || !formConfig[fieldName]) {
    return true;
  }

  return formConfig[fieldName].isRequired;
};
