import { Dispatch } from 'react';
import { Job } from '../../types/job';

export enum Types {
  Loading = 'LOADING',
  Loaded = 'LOADED',
  Error = 'ERROR',
}

export type JobLoadingPayload = {
  jobUUID: string;
};

export type JobLoadedPayload = {
  job: Job;
};

export type JobErrorPayload = {
  error: string;
};

type JobActionPayload = JobLoadedPayload | JobLoadingPayload | JobErrorPayload;

export type JobState = {
  job: Job;
  loading: boolean;
  uuid: string;
  error?: string;
};

export type JobContext = {
  state: JobState;
  dispatch: Dispatch<JobAction>;
};

export type JobAction = {
  type: Types;
  payload: JobActionPayload;
};
