import { Button, Typography } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { MomentDate } from '../../types/appointment-slot';

const AppointmentDay = ({
  date,
  selected,
  onClick,
}: {
  date: MomentDate;
  selected: boolean;
  onClick: () => void;
}) => {
  const TODAY = new Date();
  const dateIsToday = date.isSame(moment(TODAY), 'day');
  const dateIsTomorrow = date.isSame(
    moment(new Date(TODAY.getFullYear(), TODAY.getMonth(), TODAY.getDate() + 1)),
    'day',
  );
  const { t } = useTranslation();

  return (
    <Button
      sx={{
        ':hover': {
          bgcolor: 'primary.main',
          color: 'white',
        },
        color: selected ? 'white' : 'primary.main',
        marginX: 1,
        backgroundColor: selected ? 'primary.main' : 'transparent',
        paddingY: 2,
        paddingX: 2.5,
        width: 108,
        height: 108,
        borderRadius: 40,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
      }}
      onClick={() => onClick()}>
      <Typography variant="caption" style={{ fontSize: 10 }}>
        {dateIsToday
          ? t('appointmentSlotCard.today')
          : dateIsTomorrow
          ? t('appointmentSlotCard.tomorrow')
          : date.format('dddd')}
      </Typography>

      <Typography variant="h4" sx={{ lineHeight: undefined }}>
        {date.format('DD')}
      </Typography>

      <Typography>{date.format('MMMM')}</Typography>
    </Button>
  );
};

export default AppointmentDay;
