import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ErrorPage from './pages/error-page';
import { QuestionPage } from './pages/question-page';
import { MainStoreProvider } from './store/main/main-store';

import { lightTheme } from './theme/light';
import { APP_ENV } from './constants';

const router = createBrowserRouter([
  {
    path: '/:sluggifiedJobTitle/:uuid',
    element: <QuestionPage />,
  },
  {
    path: '/',
    element: <ErrorPage />,
    errorElement: <ErrorPage />,
  },
]);

const theme = createTheme(lightTheme);

export default function App() {
  return (
    <>
      <Helmet>{APP_ENV === 'stage' && <meta name="robots" content="noindex, nofollow" />}</Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MainStoreProvider>
          <RouterProvider router={router} />
        </MainStoreProvider>
      </ThemeProvider>
    </>
  );
}
