import { Box, Fab, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';
import { Types, UserContext } from '../../store/user/types';
import { userStore } from '../../store/user/user-store';
import { DelayedSlide } from '../common/delayed-slide';
import { MuiFullname } from '../common/mui-fullname';
import { DisplayedScreen } from '../../constants';
import { MuiGender } from '../common/mui-gender';
import { Gender } from '../../types/gender';
import { MuiBirthdate } from '../common/mui-birthdate';
import { MomentDate } from '../../types/appointment-slot';
import OnCardError from '../common/error/on-card-error';
import { useEnterListener } from '../../utils/common';
import { GooglePlacesAutoComplete } from '../common/google-autocomplete';
import { JobContext } from '../../store/job/types';
import { jobStore } from '../../store/job/job-store';

export const RegisterComponent = ({
  displayed,
  setDisplayedIndex,
}: {
  displayed: boolean;
  setDisplayedIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { t } = useTranslation();
  const [initialLocation, setInitialLocation] = useState<
    { lat: number; lon: number } | undefined
  >();

  const dispatch = useContextSelector<UserContext, UserContext['dispatch']>(
    userStore,
    (s) => s.dispatch,
  );

  const loading = useContextSelector<UserContext, UserContext['state']['loading']>(
    userStore,
    (s) => s.state.loading,
  );

  const registerSubmissionError = useContextSelector<
    UserContext,
    UserContext['state']['registerSubmissionError']
  >(userStore, (s) => s.state.registerSubmissionError);

  const registerError = useContextSelector<UserContext, UserContext['state']['registerError']>(
    userStore,
    (s) => s.state.registerError,
  );

  const fullnameError = useContextSelector<UserContext, UserContext['state']['fullnameError']>(
    userStore,
    (s) => s.state.fullnameError,
  );

  const birthDate = useContextSelector<UserContext, UserContext['state']['birthDate']>(
    userStore,
    (s) => s.state.birthDate,
  );

  const showVerification = useContextSelector<
    UserContext,
    UserContext['state']['showVerification']
  >(userStore, (s) => s.state.showVerification);

  const showOnboarding = useContextSelector<UserContext, UserContext['state']['showOnboarding']>(
    userStore,
    (s) => s.state.showOnboarding,
  );

  const countryCode = useContextSelector<JobContext, JobContext['state']['job']['countryCode']>(
    jobStore,
    (s) => s.state.job.countryCode,
  );

  const languageCode = useContextSelector<JobContext, JobContext['state']['job']['languageCode']>(
    jobStore,
    (s) => s.state.job.languageCode,
  );

  const onFirstNameChange = (name: string) => {
    dispatch({ type: Types.FirstNameEntered, payload: { value: name } });
  };

  const onLastNameChange = (surname: string) => {
    dispatch({ type: Types.LastNameEntered, payload: { value: surname } });
  };

  const onGenderSelect = (gender: Gender) => {
    dispatch({ type: Types.GenderSelect, payload: { gender } });
  };

  const handleSubmit = () => {
    dispatch({ type: Types.RegisterSubmitted, payload: {} });
  };

  const onBirthdateSelect = (birthdate: MomentDate) => {
    dispatch({ type: Types.BirthdateSelect, payload: { birthdate } });
  };

  const onLocationSelect = (lat: number, lon: number) => {
    dispatch({ type: Types.LocationSelected, payload: { latLon: { lat, lon } } });
  };

  const onUserGeolocationChange = (geolocation: string) => {
    dispatch({ type: Types.UserGeolocationChange, payload: { geolocation } });
  };

  useEffect(() => {
    if (showOnboarding) {
      setDisplayedIndex(DisplayedScreen.WorkerOnboarding);
    }
  }, [showOnboarding]);

  useEffect(() => {
    if (showVerification) {
      setDisplayedIndex(DisplayedScreen.VerificationCodeInput);
    }
  }, [showVerification]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setInitialLocation({ lat: position.coords.latitude, lon: position.coords.longitude });
        onLocationSelect(position.coords.latitude, position.coords.longitude);
      }, console.error);
    }
  }, []);

  useEnterListener(handleSubmit);

  return (
    <DelayedSlide iN={displayed} delay={800}>
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '60%',
            },
          }}>
          <OnCardError error={registerError} fromTop />
          <OnCardError error={registerSubmissionError} fromTop />
          <OnCardError error={fullnameError} fromTop />

          <Typography gutterBottom variant="h5" component="div">
            {t('register.tellMoreAboutYourself')}
          </Typography>

          <MuiFullname onFirstNameChange={onFirstNameChange} onLastNameChange={onLastNameChange} />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <MuiGender onGenderSelect={onGenderSelect} />

            <MuiBirthdate
              onBirthdateSelect={onBirthdateSelect}
              initialBirthdate={birthDate}
              sxProps={{
                marginLeft: '2rem',
              }}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Box
              sx={{
                width: {
                  xs: '100%',
                  sm: '50%',
                },
              }}>
              <GooglePlacesAutoComplete
                countryCode={countryCode}
                setLatLon={onLocationSelect}
                initialLocation={initialLocation}
                languageCode={languageCode}
                setUserGeolocation={onUserGeolocationChange}
              />
            </Box>
          </Box>

          <Box
            sx={{
              margin: '2rem',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Fab variant="extended" color="primary" onClick={handleSubmit} disabled={loading}>
              {loading ? (
                <div>
                  <span>{t('common.loading')}</span>
                </div>
              ) : (
                <>
                  <ArrowForwardIosTwoToneIcon sx={{ mr: 1 }} />
                  <span>{t('phoneEmail.continue')}</span>
                </>
              )}
            </Fab>
          </Box>
        </Box>
      </Box>
    </DelayedSlide>
  );
};
