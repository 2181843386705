export type QuestionChoice = {
  id: number;
  text: string;
  isContinue: boolean;
  order: number;
};

export type Question = {
  id: number;
  questionChoice?: QuestionChoice[];
  jobId: number;
  text: string;
  description: string;
  questionType: QuestionType;
  order: number;
  relation: RelationOptions;
  createdAt: Date;
  updatedAt?: Date;
  synced?: boolean;
};

export enum RelationOptions {
  NONE = 'none',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE = 'phone',
  LOCATION = 'location',
  BIRTH_DATE = 'birthDate',
  GENDER = 'gender',
  EDUCATION = 'education',
  ENGLISH_LEVEL = 'englishLevel',
}

export enum QuestionType {
  TEXT = 1,
  CHOICE = 2,
}

export type QuestionWithAnswer = Question & {
  answer?: string;
};
