import { Dispatch } from 'react';
import {
  AppointmentSlotFromApi,
  JobApplicationPayload,
} from '../../services/api-response/legacy-response';
import { AppointmentSlot, AppointmentSlots, MomentDate } from '../../types/appointment-slot';
import { QuestionWithAnswer } from '../../types/question';

export enum Types {
  Loading = 'QUESTIONS_LOADED',
  Loaded = 'QUESTIONS_LOADING',
  Answered = 'QUESTION_ANSWERED',
  DisplayQuestion = 'DISPLAY_QUESTION',
  LoadingError = 'QUESTIONS_LOADING_ERROR',
  AnsweringError = 'QUESTION_ANSWERING_ERROR',
  SetLoading = 'SET_LOADING',
  CompletePreQuestionProcesses = 'COMPLETE_PRE_QUESTION_PROCESSES',
  JobApplicationCompleted = 'JOB_APPLICATION_COMPLETED',
  SetJobId = 'SET_JOB_ID',
  Finished = 'FINISHED',
  AppointmentsLoaded = 'APPOINTMENTS_LOADED',
  SetCompanyId = 'SET_COMPANY_ID',
  SetCompanyPhone = 'SET_COMPANY_PHONE',
  SetWorkerId = 'SET_WORKER_ID',
  LastQuestionAnswered = 'LAST_QUESTION_ANSWERED',
  SelectAppointmentSlotDate = 'SELECT_APPOINTMENT_SLOT_DATE',
  SelectAppointmentSlotHour = 'SELECT_APPOINTMENT_SLOT_HOUR',
  ConfirmAppointmentSlot = 'CONFIRM_APPOINTMENT_SLOT',
  ConfirmedNotChoosingSlot = 'CONFIRMED_NOT_CHOOSING_SLOT',
  AppointmentError = 'APPOINTMENT_ERROR',
  AppointmentConfirmed = 'APPOINTMENT_CONFIRMED',
  NextQuestion = 'NEXT_QUESTION',
  PreviousQuestion = 'PREVIOUS_QUESTION',
  SetAnswer = 'SET_ANSWER',
}

export type CompletePreQuestionProcessesPayload = {
  uuid: string;
};

export type SetJobIdPayload = {
  jobId: number;
};

export type QuestionsLoadingPayload = {
  jobUUID: string;
};

export type QuestionsLoadedPayload = {
  questions: QuestionWithAnswer[];
};

export type SetAnswerPayload = {
  answer: string;
};

export type DisplayQuestionPayload = {
  next: boolean;
};

export type ErrorPayload = {
  error: string;
};

export type JobApplicationCompletedPayload = {
  applicationId: number;
  application: JobApplicationPayload;
};

export type AppointmentsLoadedPayload = {
  slots: AppointmentSlotFromApi[];
};

export type SetCompanyIdPayload = {
  companyId: number;
};

export type SetCompanyPhonePayload = {
  companyPhone: string;
};

export type SetWorkerIdPayload = {
  workerId: number;
};

export type SelectAppointmentSlotDatePayload = {
  date: MomentDate;
};

export type SelectAppointmentSlotHourPayload = {
  startAt: string;
  endAt: string;
};

export type EmptyPayload = Record<string, never>;

type QuestionPayload =
  | QuestionsLoadingPayload
  | QuestionsLoadedPayload
  | SetAnswerPayload
  | DisplayQuestionPayload
  | ErrorPayload
  | CompletePreQuestionProcessesPayload
  | JobApplicationCompletedPayload
  | SetJobIdPayload
  | AppointmentsLoadedPayload
  | SetCompanyIdPayload
  | SetCompanyPhonePayload
  | SetWorkerIdPayload
  | SelectAppointmentSlotDatePayload
  | SelectAppointmentSlotHourPayload
  | EmptyPayload;

export type QuestionFormState = {
  questions: QuestionWithAnswer[];
  displayedQuestion?: QuestionWithAnswer;
  loading: boolean;
  jobUUID: string;
  applicationId?: number;
  loadingError?: string;
  jobId?: number;
  answeringError?: string;
  finished: boolean;
  showSorry?: boolean;
  appointmentSlots?: AppointmentSlots[];
  companyId?: number;
  companyPhone?: string;
  workerId?: number;
  showAppointmentSlots?: boolean;
  selectedAppointmentSlot?: AppointmentSlot;
  appointmentDateSelected: boolean;
  appointmentError?: string;
  application?: JobApplicationPayload;
  canGoToNextQuestion: boolean;
  canGoToPreviousQuestion: boolean;
};

export type QuestionFormContext = {
  state: QuestionFormState;
  dispatch: Dispatch<QuestionFormAction>;
};

export type QuestionFormAction = {
  type: Types;
  payload: QuestionPayload;
};
