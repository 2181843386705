/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { GOOGLE_MAPS_KEY } from '../../constants';
import { GeoLocationObject } from '../../types/geolocation';

export default {
  reverseGeoLocation: async (latLon: string, language: string): Promise<string> => {
    const { data } = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLon}&result_type=administrative_area_level_1|administrative_area_level_2|administrative_area_level_3|administrative_area_level_4&language=${language}&key=${GOOGLE_MAPS_KEY}`,
    );
    const addressArr: GeoLocationObject[] = [];
    data.results[0].address_components.forEach((item: any) => {
      const addressItem: GeoLocationObject = {
        type: '',
        name: '',
      };
      addressItem.type = item.types?.length ? item.types[0] : '';
      if (addressItem.type === 'country') {
        addressItem.short_name = item.short_name;
        addressItem.name = item.long_name;
      } else {
        addressItem.name = item.long_name;
      }
      addressArr.push(addressItem);
    });
    return JSON.stringify(addressArr);
  },
  convertGeoLocationToString: (value: string): string => {
    const geoLocationObject = JSON.parse(value) as GeoLocationObject[];
    const level2 = geoLocationObject.find(
      (geoValue: any) => geoValue.type === 'administrative_area_level_2',
    )?.name;
    const level1 = geoLocationObject.find(
      (geoValue: any) => geoValue.type === 'administrative_area_level_1',
    )?.name;
    if (level2) {
      return `${level2}, ${level1}`;
    } else if (level1) {
      return `${level1}}`;
    }
    return '';
  },
};
