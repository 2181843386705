import { Box, Fab, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AppointmentSlotCard from '../components/appointment-slot/appointment-slot-card';
import { JobCard } from '../components/job/job-card';
import { QuestionForm } from '../components/question-form';
import { EmailCheckComponent } from '../components/user/email-check';
import { RegisterComponent } from '../components/user/register-card';
import { PhoneCheckComponent } from '../components/user/phone-check';
import { SMSVerifyCard } from '../components/user/sms-verifiy-card';
import { DisplayedScreen } from '../constants';
import { useContextSelector } from 'use-context-selector';
import { JobContext } from '../store/job/types';
import { jobStore } from '../store/job/job-store';
import { QuestionFormContext } from '../store/form/types';
import { formStore } from '../store/form/form-store';
import countryOptionsJson from '../utils/country/country-data.json';
import { UserContext } from '../store/user/types';
import { userStore } from '../store/user/user-store';
import { WorkerOnboarding } from '../components/worker-onboarding/worker-onboarding';

const countryOptions = JSON.parse(JSON.stringify(countryOptionsJson));

export const QuestionPage = () => {
  const { uuid } = useParams();
  const { t } = useTranslation();
  const [displayedScreenIndex, setDisplayedScreenIndex] = useState(DisplayedScreen.JobDetail);

  const job = useContextSelector<JobContext, JobContext['state']['job']>(
    jobStore,
    (s) => s.state.job,
  );

  const companyPhone = useContextSelector<
    QuestionFormContext,
    QuestionFormContext['state']['companyPhone']
  >(formStore, (s) => s.state.companyPhone);

  const application = useContextSelector<
    QuestionFormContext,
    QuestionFormContext['state']['application']
  >(formStore, (s) => s.state.application);

  const userFirstName = useContextSelector<UserContext, UserContext['state']['firstName']>(
    userStore,
    (s) => `${s.state.firstName}`,
  );
  const PHONE = `${countryOptions[job.countryCode]?.countryCode}${companyPhone}`;
  const MESSAGE = t('questionPage.whatsappMessageContent', {
    userFirstName: userFirstName,
    jobTitle: job.title,
  });
  const WHATSAPP_GENERIC_LINK = `https://wa.me/${PHONE}?text=${MESSAGE}`;

  useEffect(() => {
    if (application?.status === 'rejected') {
      setDisplayedScreenIndex(DisplayedScreen.Sorry);
    }
  }, [application]);

  return (
    <Box
      sx={{
        minHeight: '100vh',
        maxWidth: '100vw',
        backgroundImage:
          displayedScreenIndex !== DisplayedScreen.JobDetail
            ? 'url(https://images.typeform.com/images/AEVYMEwscNQr/background/large)'
            : undefined,
        backgroundPosition: 'top center',
        backgroundSize: 'cover',
      }}>
      <Box
        sx={{
          position: 'fixed',
          top: 10,
          left: 10,
        }}>
        <img
          src="/logo512.png"
          height="10%"
          width="10%"
          style={{
            borderRadius: '20%',
          }}
        />
      </Box>

      {displayedScreenIndex === DisplayedScreen.JobDetail && (
        <JobCard
          uuid={uuid}
          displayed={displayedScreenIndex === DisplayedScreen.JobDetail}
          setDisplayedIndex={setDisplayedScreenIndex}
        />
      )}

      {displayedScreenIndex === DisplayedScreen.PhoneInput && (
        <PhoneCheckComponent
          displayed={displayedScreenIndex === DisplayedScreen.PhoneInput}
          setDisplayedIndex={setDisplayedScreenIndex}
        />
      )}

      {displayedScreenIndex === DisplayedScreen.EmailInput && (
        <EmailCheckComponent
          displayed={displayedScreenIndex === DisplayedScreen.EmailInput}
          setDisplayedIndex={setDisplayedScreenIndex}
        />
      )}

      {displayedScreenIndex === DisplayedScreen.Register && (
        <RegisterComponent
          displayed={displayedScreenIndex === DisplayedScreen.Register}
          setDisplayedIndex={setDisplayedScreenIndex}
        />
      )}

      {displayedScreenIndex === DisplayedScreen.WorkerOnboarding && (
        <WorkerOnboarding
          displayed={displayedScreenIndex === DisplayedScreen.WorkerOnboarding}
          setDisplayedIndex={setDisplayedScreenIndex}
        />
      )}

      {displayedScreenIndex === DisplayedScreen.VerificationCodeInput && (
        <SMSVerifyCard
          displayed={displayedScreenIndex === DisplayedScreen.VerificationCodeInput}
          setDisplayedIndex={setDisplayedScreenIndex}
        />
      )}

      {displayedScreenIndex === DisplayedScreen.Questions && (
        <QuestionForm
          displayed={displayedScreenIndex === DisplayedScreen.Questions}
          setDisplayedScreen={setDisplayedScreenIndex}
        />
      )}

      {displayedScreenIndex === DisplayedScreen.Finished && (
        <Box
          id="successfulApplyMessage"
          sx={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            paddingX: {
              xs: '5vw',
              sm: '20vw',
            },
          }}>
          <Typography variant="h6" sx={{ textAlign: 'center', marginBottom: '16px' }}>
            {t('questionPage.finished')}
          </Typography>
          <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: '16px' }}>
            {t('questionPage.finishedDescription')}
          </Typography>
          {job.countryCode === 'MX' ? (
            <Fab
              variant="extended"
              href={WHATSAPP_GENERIC_LINK}
              style={{ color: '#FFF', backgroundColor: '#25D366' }}>
              <WhatsAppIcon sx={{ mr: 1 }} />
              <span>{t('questionPage.whatsappButtonTitle')}</span>
            </Fab>
          ) : null}
        </Box>
      )}

      {displayedScreenIndex === DisplayedScreen.Sorry && (
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingX: {
              xs: '5vw',
              sm: '20vw',
            },
          }}>
          <Typography variant="h4" sx={{ textAlign: 'center' }}>
            {t('questionPage.sorryNotAbleToApply')}
          </Typography>
        </Box>
      )}

      {displayedScreenIndex === DisplayedScreen.AppointmentSlots && (
        <AppointmentSlotCard
          displayed={displayedScreenIndex === DisplayedScreen.AppointmentSlots}
          setDisplayedScreen={setDisplayedScreenIndex}
        />
      )}
    </Box>
  );
};
