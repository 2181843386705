import { Slide } from '@mui/material';
import { useEffect, useState } from 'react';

export const DelayedSlide = ({
  children,
  iN,
  delay = 0,
}: {
  children: React.ReactElement;
  iN: boolean;
  delay: number;
}) => {
  const [isIn, setIsIn] = useState(iN && delay === 0);

  useEffect(() => {
    if (!iN) {
      setIsIn(false);
      return;
    }
    if (delay > 0) {
      setTimeout(() => setIsIn(iN), delay);
    }
  }, [iN]);

  return (
    <Slide direction="up" mountOnEnter={true} unmountOnExit={true} in={isIn}>
      {children}
    </Slide>
  );
};
