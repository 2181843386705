import { FormStateProvider } from '../form/form-store';
import { JobStateProvider } from '../job/job-store';
import { UserStateProvider } from '../user/user-store';

export const MainStoreProvider = ({ children }: { children: React.ReactNode }) => (
  <UserStateProvider>
    <FormStateProvider>
      <JobStateProvider>{children}</JobStateProvider>
    </FormStateProvider>
  </UserStateProvider>
);
