import { Box, Fab, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import { MuiEmail } from '../common/mui-email';
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';
import { Types, UserContext } from '../../store/user/types';
import { userStore } from '../../store/user/user-store';
import { DelayedSlide } from '../common/delayed-slide';
import { DisplayedScreen } from '../../constants';
import { useEnterListener } from '../../utils/common';
import OnCardError from '../common/error/on-card-error';

export const EmailCheckComponent = ({
  displayed,
  setDisplayedIndex,
}: {
  displayed: boolean;
  setDisplayedIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { t } = useTranslation();

  const dispatch = useContextSelector<UserContext, UserContext['dispatch']>(
    userStore,
    (s) => s.dispatch,
  );

  const loading = useContextSelector<UserContext, UserContext['state']['loading']>(
    userStore,
    (s) => s.state.loading,
  );

  const emailError = useContextSelector<UserContext, UserContext['state']['emailError']>(
    userStore,
    (s) => s.state.emailError,
  );

  const emailSubmissionError = useContextSelector<
    UserContext,
    UserContext['state']['emailSubmissionError']
  >(userStore, (s) => s.state.emailSubmissionError);

  const showVerification = useContextSelector<
    UserContext,
    UserContext['state']['showVerification']
  >(userStore, (s) => s.state.showVerification);

  const showRegister = useContextSelector<UserContext, UserContext['state']['showRegister']>(
    userStore,
    (s) => s.state.showRegister,
  );

  const onEmailChange = (email: string) => {
    dispatch({ type: Types.EmailEntered, payload: { email } });
  };

  const handleSubmit = () => {
    dispatch({ type: Types.EmailSubmitted, payload: {} });
  };

  useEffect(() => {
    if (showVerification) {
      setDisplayedIndex(DisplayedScreen.VerificationCodeInput);
    }
  }, [showVerification]);

  useEffect(() => {
    if (showRegister) {
      setDisplayedIndex(DisplayedScreen.Register);
    }
  }, [showRegister]);

  useEnterListener(handleSubmit);

  return (
    <DelayedSlide iN={displayed} delay={800}>
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '60%',
            },
          }}>
          <OnCardError error={emailError} fromTop />
          <OnCardError error={emailSubmissionError} fromTop />

          <Typography gutterBottom variant="h5" component="div">
            {t('emailCheck.pleaseProvideYourEmail')}
          </Typography>

          <MuiEmail onEmailChange={onEmailChange} />

          <Box
            sx={{
              margin: '1rem',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Fab variant="extended" color="primary" onClick={handleSubmit} disabled={loading}>
              {loading ? (
                <div>
                  <span>{t('common.loading')}</span>
                </div>
              ) : (
                <>
                  <ArrowForwardIosTwoToneIcon sx={{ mr: 1 }} />
                  <span>{t('phoneEmail.continue')}</span>
                </>
              )}
            </Fab>
          </Box>
        </Box>
      </Box>
    </DelayedSlide>
  );
};
