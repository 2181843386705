import { Grid } from '@mui/material';
import React from 'react';

export const CenteredComponent = ({ children }: { children: React.ReactNode }) => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '100vh' }}>
    <Grid item xs={3}>
      {children}
    </Grid>
  </Grid>
);
