import axios from 'axios';
import { X_APP_REQUEST_SOURCE, X_APP_REQUEST_SOURCE_VALUE } from './constants';

const instance = axios.create({
  withCredentials: true,
});

instance.defaults.withCredentials = true;
instance.defaults.headers.common[X_APP_REQUEST_SOURCE] = X_APP_REQUEST_SOURCE_VALUE;

export default instance;
