import moment from 'moment';
import React, { useReducer } from 'react';
import { createContext } from 'use-context-selector';
import {
  EmailResponseMessage,
  PhoneResponseMessage,
  VerificationCodeResponseMessage,
} from '../../services/api-response/legacy-response/types';
import { LegacyAPI } from '../../services/legacy-api/api';

import {
  BirthdateSelectPayload,
  CountrySelectedPayload,
  CurrentWorkStatusSelectedPayload,
  DrivingLicencesSelectedPayload,
  EducationSelectedPayload,
  EmailEnteredPayload,
  EnglishLevelSelectedPayload,
  ErrorPayload,
  ExpectedSalarySelectedPayload,
  GenderSelectPayload,
  LocationSelectedPayload,
  MilitaryStatusSelectedPayload,
  NameOrSurnameEnteredPayload,
  PersonInfosSubmitErrorPayload,
  PersonalInfosSubmittedPayload,
  PhoneEnteredPayload,
  SMSCodeEnteredPayload,
  ShowOnboardingPayload,
  Types,
  UserAction,
  UserContext,
  UserGeolocationChangePayload,
  UserState,
  WorkTimePreferencesSelectedPayload,
} from './types';
import {
  isFieldRequired,
  validateEmailInput,
  validateFullnameInput,
  validatePhoneInput,
} from './utils';
import { Gender } from '../../types/gender';

const initialState: UserContext = {
  state: {
    phoneNumber: '',
    country: 'TR',
    verificationCode: '',
    loading: false,
    showVerification: false,
    showEmailCheck: false,
    showRegister: false,
    showQuestions: false,
    isRegistering: false,
    birthDate: moment(new Date()).subtract(15, 'years'),
    showOnboarding: false,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
};
const userStore = createContext<UserContext>(initialState);
const { Provider } = userStore;

const UserStateProvider = ({ children }: { children: React.ReactNode }) => {
  const submitPhone = async (phoneNumber: string, country: string): Promise<void> => {
    try {
      const api = new LegacyAPI();

      const result = await api.checkPhone(phoneNumber, country);

      if (result.payload.message === PhoneResponseMessage.NOT_WORKER) {
        dispatch({ type: Types.PhoneSubmissionError, payload: { error: 'phoneCheck.notWorker' } });
        return;
      }

      if (result.payload.message === PhoneResponseMessage.VERIFIED_PHONE_NOT_FOUND) {
        dispatch({ type: Types.ShowEmailCheck, payload: {} });
        return;
      }

      if (result.payload.message === PhoneResponseMessage.SMS_SENT) {
        dispatch({ type: Types.ShowVerification, payload: {} });
        return;
      }

      dispatch({
        type: Types.PhoneSubmissionError,
        payload: { error: 'common.somethingWentWrong' },
      });
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: Types.PhoneSubmissionError, payload: { error: e.message } });
        return;
      }
    }
    dispatch({ type: Types.PhoneSubmissionError, payload: { error: 'common.somethingWentWrong' } });
  };

  const submitEmail = async (
    email: string,
    phoneNumber: string,
    country: string,
  ): Promise<void> => {
    try {
      const api = new LegacyAPI();

      const result = await api.checkEmail(email, phoneNumber, country);

      if (result.payload.message === EmailResponseMessage.NON_VERIFIED_USER) {
        dispatch({ type: Types.ShowVerification, payload: {} });
        return;
      }

      if (result.payload.message === EmailResponseMessage.EMAIL_IN_USE) {
        dispatch({ type: Types.EmailSubmissionError, payload: { error: 'emailCheck.emailInUse' } });
        return;
      }

      if (result.payload.message === EmailResponseMessage.EMAIL_AVAILABLE) {
        dispatch({ type: Types.ShowVerificationThenNameSurname, payload: {} });
        return;
      }

      dispatch({
        type: Types.EmailSubmissionError,
        payload: { error: 'common.somethingWentWrong' },
      });
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: Types.EmailSubmissionError, payload: { error: e.message } });
        return;
      }
    }
    dispatch({
      type: Types.EmailSubmissionError,
      payload: { error: 'common.somethingWentWrong' },
    });
  };

  const registerUser = async ({
    email,
    phoneNumber,
    country,
    firstName,
    lastName,
    verificationCode,
    gender,
    birthDate,
    latLon,
    geolocation,
  }: UserState): Promise<void> => {
    try {
      if (!email || !firstName || !lastName || !gender || !latLon) {
        dispatch({
          type: Types.RegisterSubmissionError,
          payload: { error: 'common.somethingWentWrong' },
        });

        return;
      }

      const api = new LegacyAPI();

      const geolocationString =
        typeof geolocation === 'string' ? geolocation : JSON.stringify(geolocation);

      const result = await api.registerUser(
        email,
        phoneNumber,
        country,
        firstName,
        lastName,
        verificationCode,
        gender,
        birthDate,
        latLon,
        geolocationString,
      );

      if (result.payload.message === VerificationCodeResponseMessage.SUCCESS) {
        dispatch({ type: Types.ShowOnboarding, payload: { result } });

        return;
      }

      if (result.payload.message === VerificationCodeResponseMessage.ERROR) {
        dispatch({
          type: Types.RegisterCodeError,
          payload: {},
        });

        return;
      }
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: Types.RegisterSubmissionError, payload: { error: e.message } });
        return;
      }

      dispatch({
        type: Types.RegisterSubmissionError,
        payload: { error: 'common.somethingWentWrong' },
      });
    }

    dispatch({
      type: Types.RegisterSubmissionError,
      payload: { error: 'common.somethingWentWrong' },
    });
  };

  const submitVerificationCode = async (
    verificationCode: string,
    phoneNumber: string,
    country: string,
    isRegistering: boolean,
    email?: string,
  ): Promise<void> => {
    try {
      const api = new LegacyAPI();
      const result = await api.submitVerificationCode(
        verificationCode,
        phoneNumber,
        country,
        email,
      );

      if (result.payload.message === VerificationCodeResponseMessage.SUCCESS) {
        if (isRegistering) {
          dispatch({ type: Types.ShowRegister, payload: {} });
          return;
        }

        dispatch({ type: Types.ShowQuestions, payload: {} });

        return;
      }

      if (result.payload.message === VerificationCodeResponseMessage.ERROR) {
        dispatch({
          type: Types.SMSCodeSubmissionError,
          payload: { error: 'verification.invalidCode' },
        });
        return;
      }
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: Types.SMSCodeSubmissionError, payload: { error: e.message } });
        return;
      }
    }

    dispatch({
      type: Types.SMSCodeSubmissionError,
      payload: { error: 'common.somethingWentWrong' },
    });
  };

  const updateUser = async ({
    firstName,
    email,
    lastName,
    accessToken,
    workTimePreferences,
    currentWorkStatus,
    drivingLicences,
    militaryStatus,
    education,
    expectedSalary,
    phoneNumber,
  }: UserState) => {
    try {
      if (!firstName || !email || !lastName) {
        dispatch({
          type: Types.PersonInfosSubmitError,
          payload: { error: 'common.somethingWentWrong' },
        });
        return;
      }

      const api = new LegacyAPI();
      api.config.headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      await api.updateWorkTimePreferences(workTimePreferences);
      await api.updateCurrentWorkStatus(currentWorkStatus);
      await api.updateDrivingLicences(drivingLicences);
      await api.updateProfile({
        first_name: firstName,
        email: email,
        last_name: lastName,
        military_status: militaryStatus,
        education_status: education,
        expected_salary: expectedSalary,
        phone: phoneNumber,
      });

      dispatch({ type: Types.ShowQuestions, payload: {} });
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: Types.PersonInfosSubmitError, payload: { error: e.message } });
        return;
      }

      dispatch({
        type: Types.PersonInfosSubmitError,
        payload: { error: 'common.somethingWentWrong' },
      });
    }
  };

  const [state, dispatch] = useReducer((state: UserState, action: UserAction) => {
    let newState: UserState;
    switch (action.type) {
      case Types.BirthdateSelect: {
        const selectedBirthDate = (action.payload as BirthdateSelectPayload).birthdate;

        if (selectedBirthDate.isAfter(moment().subtract(16, 'years'))) {
          newState = {
            ...state,
            birthDate: selectedBirthDate,
            registerError: 'register.birthdateTooYoung',
          };

          return newState;
        }

        newState = {
          ...state,
          birthDate: selectedBirthDate,
          registerError: undefined,
        };

        return newState;
      }
      case Types.GenderSelect: {
        newState = {
          ...state,
          gender: (action.payload as GenderSelectPayload).gender,
        };

        return newState;
      }
      case Types.CountrySelected: {
        newState = { ...state, country: (action.payload as CountrySelectedPayload).country };

        return newState;
      }
      case Types.PhoneEntered: {
        newState = {
          ...state,
          phoneNumber: (action.payload as PhoneEnteredPayload).phoneNumber,
        };

        return newState;
      }
      case Types.EmailEntered: {
        newState = {
          ...state,
          email: (action.payload as EmailEnteredPayload).email,
        };

        return newState;
      }
      case Types.FirstNameEntered: {
        newState = {
          ...state,
          firstName: (action.payload as NameOrSurnameEnteredPayload).value,
        };
        return newState;
      }
      case Types.LastNameEntered: {
        newState = {
          ...state,
          lastName: (action.payload as NameOrSurnameEnteredPayload).value,
        };
        return newState;
      }
      case Types.PhoneSubmitted: {
        const { phoneError } = validatePhoneInput(state.phoneNumber, state.country);

        if (!phoneError) {
          newState = {
            ...state,
            loading: true,
            phoneError,
          };

          submitPhone(state.phoneNumber, state.country);

          return newState;
        }

        newState = {
          ...state,
          phoneError,
        };

        return newState;
      }
      case Types.EmailSubmitted: {
        const { emailError } = validateEmailInput(state.email ?? '');

        if (!emailError && state.email) {
          newState = {
            ...state,
            loading: true,
            emailError,
          };

          submitEmail(state.email, state.phoneNumber, state.country);

          return newState;
        }

        newState = {
          ...state,
          emailError,
        };

        return newState;
      }
      case Types.ShowVerification: {
        newState = {
          ...state,
          showVerification: true,
          loading: false,
        };

        return newState;
      }
      case Types.SMSCodeEntered: {
        newState = {
          ...state,
          verificationCode: (action.payload as SMSCodeEnteredPayload).code,
        };

        return newState;
      }
      case Types.SMSCodeSubmitted: {
        if (state.verificationReregister) {
          newState = {
            ...state,
            loading: true,
          };

          registerUser(state);

          return newState;
        }

        if (state.isRegistering) {
          newState = {
            ...state,
            loading: false,
            showRegister: true,
            showVerification: false,
          };
          return newState;
        }
        newState = {
          ...state,
          loading: true,
        };
        submitVerificationCode(
          state.verificationCode,
          state.phoneNumber,
          state.country,
          state.isRegistering,
          state.email,
        );

        return newState;
      }
      case Types.PhoneSubmissionError: {
        newState = {
          ...state,
          loading: false,
          phoneSubmissionError: (action.payload as ErrorPayload).error,
        };

        return newState;
      }
      case Types.EmailSubmissionError: {
        newState = {
          ...state,
          loading: false,
          emailSubmissionError: (action.payload as ErrorPayload).error,
        };

        return newState;
      }
      case Types.SMSCodeSubmissionError: {
        newState = {
          ...state,
          loading: false,
          verificationError: (action.payload as ErrorPayload).error,
        };

        return newState;
      }
      case Types.ShowEmailCheck: {
        newState = {
          ...state,
          showEmailCheck: true,
          loading: false,
        };

        return newState;
      }
      case Types.ShowRegister: {
        newState = {
          ...state,
          showRegister: true,
          loading: false,
        };

        return newState;
      }
      case Types.ShowQuestions: {
        newState = {
          ...state,
          showQuestions: true,
          loading: false,
        };

        return newState;
      }
      case Types.ShowVerificationThenNameSurname: {
        newState = {
          ...state,
          showVerification: true,
          isRegistering: true,
          loading: false,
        };
        return newState;
      }
      case Types.RegisterSubmitted: {
        const { fullnameError } = validateFullnameInput(
          state.firstName ?? '',
          state.lastName ?? '',
        );

        if (!state.gender) {
          newState = {
            ...state,
            registerError: 'register.genderRequired',
          };

          return newState;
        }

        if (!state.latLon) {
          newState = {
            ...state,
            registerError: 'register.locationRequired',
          };

          return newState;
        }

        if (!state.birthDate) {
          newState = {
            ...state,
            registerError: 'register.birthdateRequired',
          };

          return newState;
        }

        if (state.birthDate.isAfter(moment().subtract(16, 'years'))) {
          newState = {
            ...state,
            registerError: 'register.birthdateTooYoung',
          };

          return newState;
        }

        if (!fullnameError && state.firstName && state.lastName) {
          newState = {
            ...state,
            loading: true,
            fullnameError,
          };

          registerUser(state);

          return newState;
        }

        newState = {
          ...state,
          fullnameError,
        };

        return newState;
      }
      case Types.RegisterSubmissionError: {
        newState = {
          ...state,
          loading: false,
          registerSubmissionError: (action.payload as ErrorPayload).error,
        };

        return newState;
      }
      case Types.RegisterCodeError: {
        newState = {
          ...state,
          loading: false,
          registerCodeError: 'register.codeError',
          showVerification: true,
          verificationReregister: true,
          verificationCode: '',
          showRegister: false,
        };

        return newState;
      }
      case Types.LocationSelected: {
        const { latLon } = action.payload as LocationSelectedPayload;

        newState = {
          ...state,
          latLon,
        };

        return newState;
      }
      case Types.UserGeolocationChange: {
        const { geolocation } = action.payload as UserGeolocationChangePayload;
        newState = {
          ...state,
          geolocation,
        };

        return newState;
      }
      case Types.ShowOnboarding: {
        const { result } = action.payload as ShowOnboardingPayload;
        newState = {
          ...state,
          showOnboarding: true,
          accessToken: result.payload.access_token,
          loading: false,
        };

        return newState;
      }
      case Types.EducationSelect: {
        const { education } = action.payload as EducationSelectedPayload;
        newState = {
          ...state,
          education,
        };

        return newState;
      }
      case Types.EnglishLevelSelect: {
        const { englishLevel } = action.payload as EnglishLevelSelectedPayload;
        newState = {
          ...state,
          englishLevel,
        };

        return newState;
      }
      case Types.MilitaryStatusSelect: {
        const { militaryStatus } = action.payload as MilitaryStatusSelectedPayload;
        newState = {
          ...state,
          militaryStatus,
        };

        return newState;
      }
      case Types.DrivingLicencesSelect: {
        const { drivingLicences } = action.payload as DrivingLicencesSelectedPayload;
        newState = {
          ...state,
          drivingLicences,
        };

        return newState;
      }
      case Types.CurrentWorkStatusSelect: {
        const { currentWorkStatus } = action.payload as CurrentWorkStatusSelectedPayload;
        newState = {
          ...state,
          currentWorkStatus,
        };

        return newState;
      }
      case Types.WorkTimePreferencesSelect: {
        const { workTimePreferences } = action.payload as WorkTimePreferencesSelectedPayload;
        newState = {
          ...state,
          workTimePreferences,
        };

        return newState;
      }
      case Types.ExpectedSalarySelect: {
        const { expectedSalary } = action.payload as ExpectedSalarySelectedPayload;
        newState = {
          ...state,
          expectedSalary,
        };

        return newState;
      }
      case Types.PersonInfosSubmitError: {
        const { error } = action.payload as PersonInfosSubmitErrorPayload;
        newState = {
          ...state,
          personalInfosSubmitError: error,
          loading: false,
        };
        return newState;
      }
      case Types.PersonalInfosSubmitted: {
        const { formConfig } = action.payload as PersonalInfosSubmittedPayload;
        state.personalInfosSubmitError = undefined;
        newState = {
          ...state,
          loading: true,
        };

        const validateAndDispatch = (field: string, errorKey: string, condition: boolean) => {
          if (isFieldRequired(formConfig, field) && condition) {
            newState = {
              ...state,
              personalInfosSubmitError: `register.${errorKey}Required`,
              loading: false,
            };
            return newState;
          }
        };

        validateAndDispatch('education', 'education', state.education === undefined);
        validateAndDispatch('english_level', 'englishLevel', state.englishLevel === undefined);
        validateAndDispatch(
          'military_status',
          'militaryStatus',
          !state.militaryStatus && state.gender === Gender.MALE,
        );
        validateAndDispatch(
          'drivingLicences',
          'drivingLicence',
          state.drivingLicences === undefined,
        );
        validateAndDispatch(
          'currentWorkStatus',
          'currentWorkStatus',
          state.currentWorkStatus === undefined,
        );
        validateAndDispatch(
          'workTimePreferences',
          'workTimePreferences',
          !state.workTimePreferences || state.workTimePreferences?.length < 1,
        );
        validateAndDispatch('expectedSalary', 'expectedSalary', !state.expectedSalary);

        if (newState.personalInfosSubmitError) {
          return newState;
        }

        updateUser(state);

        return newState;
      }
      default:
        throw new Error();
    }
  }, initialState.state);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { userStore, UserStateProvider };
