export enum PhoneResponseMessage {
  NOT_WORKER = 'not_worker',
  SMS_SENT = 'sms_sent',
  VERIFIED_PHONE_NOT_FOUND = 'verified_phone_not_found',
}

export enum EmailResponseMessage {
  NON_VERIFIED_USER = 'non_verified_user',
  EMAIL_IN_USE = 'email_in_use',
  EMAIL_AVAILABLE = 'email_available',
}

export enum VerificationCodeResponseMessage {
  SUCCESS = 'success',
  ERROR = 'user_phone_verified_code_error',
}

export enum RegisterUserResponseMessage {
  SUCCESS = 'success',
  ERROR = 'user_phone_verified_code_error',
}

export type ResponseMessage =
  | PhoneResponseMessage
  | EmailResponseMessage
  | VerificationCodeResponseMessage
  | RegisterUserResponseMessage;
