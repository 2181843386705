import { Button, Typography } from '@mui/material';

const AppointmentHour = ({
  startAt,
  selected,
  onClick,
}: {
  startAt: string;
  selected: boolean;
  onClick: () => void;
}) => {
  return (
    <Button
      onClick={() => onClick()}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 8,
        backgroundColor: selected ? 'primary.main' : 'common.white',
        borderRadius: 8,
        borderWidth: 2,
        width: '80%',
        color: selected ? 'white' : 'primary.main',
      }}>
      <Typography variant="h6">{startAt}</Typography>
    </Button>
  );
};

export default AppointmentHour;
