import { useState } from 'react';
import { GOOGLE_MAPS_KEY } from '../../constants';

const center = { lat: 41.060121, lng: 29.0 };

const generateGoogleStaticMapsUrl = (params: { [key: string]: string | number }) => {
  const baseUrl = 'https://maps.googleapis.com/maps/api/staticmap';

  const defaultParams = {
    size: '800x800',
    key: GOOGLE_MAPS_KEY ?? '',
  };

  const queryParams = { ...params, ...defaultParams };

  const query = Object.entries(queryParams)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const googleStaticMapsUrl = `${baseUrl}?${query}`;

  return googleStaticMapsUrl;
};

export const StaticGoogleMaps = ({ lat, lng }: { lat?: number; lng?: number }) => {
  const [location] = useState({ lat: lat ?? center.lat, lng: lng ?? center.lng });

  const imageUrl = generateGoogleStaticMapsUrl({
    center: `${location.lat},${location.lng}`,
    zoom: 15,
    markers: `color:red%7C${location.lat},${location.lng}`,
  });

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: 'hidden',
      }}>
      <a
        href={`https://www.google.com/maps?q=${location.lat},${location.lng}&zoom=15`}
        target="_blank"
        rel="noreferrer">
        <img src={imageUrl} />
      </a>
    </div>
  );
};
