import { Box, CircularProgress, Grid, Modal, Typography, Fab, SxProps, Theme } from '@mui/material';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import { DisplayedScreen } from '../../constants';
import { formStore } from '../../store/form/form-store';
import { QuestionFormContext, Types } from '../../store/form/types';
import { DelayedSlide } from '../common/delayed-slide';
import OnCardError from '../common/error/on-card-error';
import AppointmentDay from './appointment-day';
import AppointmentHour from './appointment-hour';
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';
import PlaceIcon from '@mui/icons-material/Place';
import { JobContext } from '../../store/job/types';
import { jobStore } from '../../store/job/job-store';

const modalStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '90vw',
    sm: '40vw',
  },
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderColor: 'primary.main',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: 10,
};

const AppointmentSlotCard = ({
  displayed,
  setDisplayedScreen,
}: {
  displayed: boolean;
  setDisplayedScreen: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { t } = useTranslation();
  const [stateError, setStateError] = useState<string | undefined>();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [unsuitableContentModalOpen, setUnsuitableContentModalOpen] = useState(false);

  const appointmentSlots = useContextSelector<
    QuestionFormContext,
    QuestionFormContext['state']['appointmentSlots']
  >(formStore, (s) => s.state.appointmentSlots);

  const loading = useContextSelector<QuestionFormContext, QuestionFormContext['state']['loading']>(
    formStore,
    (s) => s.state.loading,
  );

  const selectedAppointmentSlot = useContextSelector<
    QuestionFormContext,
    QuestionFormContext['state']['selectedAppointmentSlot']
  >(formStore, (s) => s.state.selectedAppointmentSlot);

  const dispatch = useContextSelector<QuestionFormContext, QuestionFormContext['dispatch']>(
    formStore,
    (s) => s.dispatch,
  );

  const loadingError = useContextSelector<
    QuestionFormContext,
    QuestionFormContext['state']['loadingError']
  >(formStore, (s) => s.state.loadingError);

  const appointmentDateSelected = useContextSelector<
    QuestionFormContext,
    QuestionFormContext['state']['appointmentDateSelected']
  >(formStore, (s) => s.state.appointmentDateSelected);

  const finished = useContextSelector<
    QuestionFormContext,
    QuestionFormContext['state']['finished']
  >(formStore, (s) => s.state.finished);

  const interviewLocation = useContextSelector<
    JobContext,
    JobContext['state']['job']['interviewLocation']
  >(jobStore, (s) => s.state.job.interviewLocation);

  useEffect(() => {
    if (finished) {
      setDisplayedScreen(DisplayedScreen.Finished);
    }
  }, [finished]);

  const renderAvailableDates = () => {
    const dates = appointmentSlots?.map((slot) => ({ date: slot.date }));

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          overflowX: 'scroll',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          '&::-webkit-scrollbar': { display: 'none' },
        }}>
        {dates?.map((date, index) => (
          <AppointmentDay
            key={index}
            date={date.date}
            selected={selectedAppointmentSlot?.date.isSame(date.date, 'day') ?? false}
            onClick={() =>
              dispatch({ type: Types.SelectAppointmentSlotDate, payload: { date: date.date } })
            }
          />
        ))}
      </Box>
    );
  };

  const renderAppointmentHours = () => {
    if (!appointmentDateSelected) {
      return <></>;
    }

    const slots = appointmentSlots?.find((slot) =>
      slot.date.isSame(selectedAppointmentSlot?.date, 'day'),
    )?.slots;

    if (!slots) {
      setStateError('common.somethingWentWrong');
      return <></>;
    }

    return (
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: '5vh',
          paddingX: {
            sm: '30vw',
          },
        }}>
        {slots.map((slot, index) => (
          <Grid
            item
            xs={6}
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <AppointmentHour
              startAt={slot.startAt}
              selected={selectedAppointmentSlot?.startAt === slot.startAt}
              onClick={() =>
                dispatch({
                  type: Types.SelectAppointmentSlotHour,
                  payload: { startAt: slot.startAt, endAt: slot.endAt },
                })
              }
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderUnsuitableContent = () => {
    return (
      <Box
        sx={{
          textAlign: 'center',
          marginTop: '5vh',
        }}>
        <Trans
          i18nKey={'appointmentSlotCard.unsuitableContent'}
          components={[
            <Typography key={0} />,
            <Typography
              key={1}
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                setUnsuitableContentModalOpen(true);
              }}
            />,
          ]}
        />
      </Box>
    );
  };

  const renderTitle = () => {
    return (
      <Typography
        variant="h4"
        sx={{
          marginBottom: '5vh',
        }}>
        {t('appointmentSlotCard.title')}
      </Typography>
    );
  };

  const renderInterviewerLocation = () => {
    return interviewLocation ? (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '2vh',
          flexDirection: 'row',
        }}>
        <PlaceIcon color="primary" />
        <Typography
          component="legend"
          sx={{
            textAlign: 'center',
          }}>
          {interviewLocation}
        </Typography>
      </Box>
    ) : (
      <></>
    );
  };

  const renderDescription = () => {
    return (
      <Typography
        variant="h5"
        sx={{
          textAlign: 'center',
          marginBottom: '5vh',
          paddingX: {
            sm: '30vw',
          },
        }}>
        {t('appointmentSlotCard.description')}
      </Typography>
    );
  };

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setConfirmationModalOpen(false);
    dispatch({ type: Types.ConfirmAppointmentSlot, payload: {} });
  };

  const handleUnsuitableConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setUnsuitableContentModalOpen(false);
    dispatch({ type: Types.ConfirmedNotChoosingSlot, payload: {} });
  };

  const cardContent = () => (
    <>
      {renderTitle()}
      {renderDescription()}
      {renderInterviewerLocation()}
      {renderAvailableDates()}
      {renderAppointmentHours()}

      <Fab
        variant="extended"
        color="primary"
        onClick={() => {
          setConfirmationModalOpen(true);
        }}
        disabled={
          !selectedAppointmentSlot ||
          !(
            !!selectedAppointmentSlot.date &&
            !!selectedAppointmentSlot.startAt &&
            !!selectedAppointmentSlot.endAt
          )
        }
        sx={{
          marginTop: '5vh',
        }}>
        <ArrowForwardIosTwoToneIcon sx={{ mr: 1 }} />
        <span>{t('appointmentSlotCard.createAppointment')}</span>
      </Fab>

      {renderUnsuitableContent()}
    </>
  );

  return (
    <DelayedSlide delay={800} iN={displayed}>
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          overflowY: 'scroll',
          paddingTop: {
            xs: '6vh',
            sm: '3vh',
          },
          paddingX: '1vw',
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <OnCardError error={loadingError} />
          <OnCardError error={stateError} />

          {loading && !loadingError ? (
            <CircularProgress color="primary" sx={{ minHeight: 1 }} />
          ) : (
            cardContent()
          )}
        </Box>
        <Modal
          open={confirmationModalOpen}
          aria-labelledby="confirmation-modal-modal-title"
          aria-describedby="confirmation-modal-modal-description">
          <Box sx={modalStyle}>
            <Typography id="confirmation-modal-modal-title" variant="h6" component="h2">
              {selectedAppointmentSlot?.date?.format('D MMMM dddd')}
            </Typography>
            <Typography id="confirmation-modal-modal-description" variant="h4" sx={{ my: 2 }}>
              {selectedAppointmentSlot?.startAt}
            </Typography>
            <Fab variant="extended" color="primary" onClick={handleConfirm}>
              <ArrowForwardIosTwoToneIcon sx={{ mr: 1 }} />
              <span>{t('appointmentSlotCard.confirm')}</span>
            </Fab>
          </Box>
        </Modal>
        <Modal
          open={unsuitableContentModalOpen}
          aria-describedby="unsuitable-modal-modal-description">
          <Box sx={modalStyle}>
            <Typography id="unsuitable-modal-modal-description" sx={{ mt: 2 }}>
              {t('appointmentSlotCard.unsuitableContentModalDescription')}
            </Typography>
            <Fab variant="extended" color="primary" onClick={handleUnsuitableConfirm}>
              <ArrowForwardIosTwoToneIcon sx={{ mr: 1 }} />
              <span>{t('appointmentSlotCard.confirm')}</span>
            </Fab>
          </Box>
        </Modal>
      </Box>
    </DelayedSlide>
  );
};

export default AppointmentSlotCard;
