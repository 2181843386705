import moment from 'moment';
import { useEffect } from 'react';
import i18n from '../../i18n';

export const isValidEmail = (email: string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  );

export const getLocale = () => {
  const locale = localStorage.getItem('locale');
  return locale ?? (navigator.languages ? navigator.languages[0] : navigator.language);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useEnterListener = (onEnterKeydown: () => void, dependencies: any[] = []) =>
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        onEnterKeydown();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, dependencies);

export const setAppLocale = (locale: string) => {
  localStorage.setItem('locale', locale);
  localStorage.setItem('I18N_LANGUAGE', locale);
  moment.locale(locale);
  i18n.changeLanguage(locale);
};
