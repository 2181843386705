import { ThemeOptions } from '@mui/material';

import red from '@mui/material/colors/red';
import yellow from '@mui/material/colors/yellow';
import green from '@mui/material/colors/green';
import lightBlue from '@mui/material/colors/lightBlue';
import grey from '@mui/material/colors/grey';

export const bondedRed = {
  50: '#ff8c9c',
  100: '#ff7488',
  200: '#ff5d74',
  300: '#ff4661',
  400: '#ff2f4d',
  500: '#ff1839',
  600: '#e61633',
  700: '#cc132e',
  800: '#b31128',
  900: '#990e22',
};

export const lightTheme: ThemeOptions = {
  transitions: {
    duration: {
      enteringScreen: 200,
      leavingScreen: 200,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: bondedRed[500],
    },
    secondary: {
      light: bondedRed[300],
      main: bondedRed[700],
      dark: bondedRed[900],
      contrastText: grey[50],
    },
    error: {
      light: red[400],
      main: red[500],
      dark: red[300],
      contrastText: grey[800],
    },
    success: {
      main: green[500],
    },
    warning: {
      main: yellow[500],
      contrastText: grey[800],
    },
    info: {
      main: lightBlue[500],
    },
    text: {
      primary: grey[900],
      secondary: grey[700],
      disabled: grey[500],
    },
    action: {
      active: red[200],
      activatedOpacity: 1,
      disabled: grey[700],
      disabledBackground: grey[200],
      hover: red[100],
      hoverOpacity: 0.7,
      focus: red[600],
      focusOpacity: 1,
      selected: red[300],
      selectedOpacity: 1,
    },
    background: {
      default: '#fff',
      paper: grey[200],
    },
    common: {
      black: grey[900],
      white: grey[200],
    },
    tonalOffset: 0.2,
  },
};
