import { FormControl, MenuItem, TextField } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Gender } from '../../types/gender';

export const MuiGender = ({ onGenderSelect }: { onGenderSelect: (gender: Gender) => void }) => {
  const { t } = useTranslation();
  const [gender, setGender] = useState<Gender | undefined>(undefined);

  return (
    <FormControl fullWidth sx={{ marginY: '2rem' }}>
      <TextField
        select
        id="gender"
        value={gender}
        onChange={(e) => {
          setGender(e.target.value as Gender);
          onGenderSelect(e.target.value as Gender);
        }}
        label={t('common.gender')}
        variant="standard">
        <MenuItem value={Gender.FEMALE}>{t('common.female')}</MenuItem>
        <MenuItem value={Gender.MALE}>{t('common.male')}</MenuItem>
      </TextField>
    </FormControl>
  );
};
