import axios from 'axios';

export type ServiceResponse<T> = {
  code: number;
  payload: T;
};

export const checkIfAxiosErrorAndUnauthenticatedAndThrowError = (e: unknown, service: string) => {
  if (axios.isAxiosError(e) && e.response?.status === 401) {
    throw new MicroserviceError(service);
  }
};

export class MicroserviceError extends Error {
  constructor(service: string) {
    super(
      `${service} Service is not authenticated. Please logout and re-login if you want to use ${service} Service functions!`,
    );
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
