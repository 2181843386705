import { Box, Typography } from '@mui/material';
import { Job } from '../../types/job';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useTranslation } from 'react-i18next';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import grey from '@mui/material/colors/grey';

export const JobOppurtunityRow = ({ job }: { job: Job }) => {
  const { t } = useTranslation();

  const styles = {
    container: {
      paddingY: '0.5rem',
      marginY: '0.3rem',
      width: '100%',
      backgroundColor: grey[100],
      borderRadius: '0.5rem',
    },
    item: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    text: {
      textAlign: 'left',
      paddingLeft: '0.3rem',
      fontSize: {
        xs: '0.8rem',
        sm: '1rem',
      },
    },
  };

  return (
    <Box sx={styles.container}>
      <Grid2
        container
        spacing={0}
        sx={{
          paddingX: '1rem',
        }}>
        <Grid2
          xs={4}
          sx={{
            display: 'flex',
            justifyContent: 'start',
            flexDirection: 'row',
          }}>
          <Box sx={styles.item}>
            {typeof job.fare === 'boolean' ? (
              job.fare ? (
                <CheckCircleIcon color="success" />
              ) : (
                <HighlightOffIcon color="secondary" />
              )
            ) : (
              <HelpOutlineIcon color="secondary" />
            )}
            <Typography sx={styles.text} color={'#454F63'}>
              {t('jobDetail.fare')}
            </Typography>
          </Box>
        </Grid2>
        <Grid2 xs={4}>
          <Box sx={styles.item}>
            {typeof job.freeMeal === 'boolean' ? (
              job.freeMeal ? (
                <CheckCircleIcon color="success" />
              ) : (
                <HighlightOffIcon color="secondary" />
              )
            ) : (
              <HelpOutlineIcon color="secondary" />
            )}
            <Typography sx={styles.text} color={'#454F63'}>
              {t('jobDetail.meal')}
            </Typography>
          </Box>
        </Grid2>
        <Grid2
          xs={4}
          sx={{
            display: 'flex',
            justifyContent: 'end',
            flexDirection: 'row',
          }}>
          <Box sx={styles.item}>
            {typeof job.healthInsurance === 'boolean' ? (
              job.healthInsurance ? (
                <CheckCircleIcon color="success" />
              ) : (
                <HighlightOffIcon color="secondary" />
              )
            ) : (
              <HelpOutlineIcon color="secondary" />
            )}
            <Typography sx={styles.text} color={'#454F63'}>
              {t('jobDetail.agi')}
            </Typography>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};
