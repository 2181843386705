import { Box, FormControl, Input, Typography } from '@mui/material';
import { QuestionWithAnswer } from '../../types/question';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

type OpenAnswerQuestionProps = {
  question: QuestionWithAnswer;
  onChange: (value: string) => void;
};

const OpenAnswerQuestion = (props: OpenAnswerQuestionProps) => {
  const { question, onChange } = props;
  const { t } = useTranslation();
  const [value, setValue] = useState<string>(question.answer ?? '');

  useEffect(() => {
    if (question) {
      setValue(question.answer ?? '');
    }
  }, [question]);

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}>
      <Typography
        gutterBottom
        variant="h5"
        component="div"
        sx={{
          width: '100%',
        }}>
        {question.text}
      </Typography>

      <FormControl
        sx={{
          marginY: '2rem',
          width: '100%',
        }}>
        {question.description && <Typography component="legend">{question.description}</Typography>}
        <Input
          autoFocus
          id={`answerText${question.id}`}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
            setValue(e.target.value);
          }}
          type="text"
          placeholder={t('constant.typeYourAnswerHere') ?? ''}
          sx={{
            height: '6vh',
            fontSize: '1.5rem',
          }}
        />
      </FormControl>
    </Box>
  );
};

export default OpenAnswerQuestion;
