import CountryCodeMenuItem from './country-code-menu-item';
import { Box, FormControl, Input, MenuItem, Select } from '@mui/material';

import countryOptionsJson from '../../utils/country/country-data.json';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const countryOptions = JSON.parse(JSON.stringify(countryOptionsJson));

export const MuiPhone = ({
  onCountryChange,
  onPhoneChange,
  initialCountryCode,
}: {
  onCountryChange: (country: string) => void;
  onPhoneChange: (phoneNumber: string) => void;
  initialCountryCode?: string;
}) => {
  const { t } = useTranslation();
  const [phoneValue, setPhoneValue] = useState('');
  const [countryCodeValue, setCountryCodeValue] = useState(initialCountryCode ?? 'TR');

  const menuItems = () => {
    if (countryOptions[countryCodeValue]) {
      const countryData = {
        ...countryOptions[countryCodeValue],
        countryAbrv: countryCodeValue,
      };

      return (
        <MenuItem value={countryCodeValue} key={countryCodeValue}>
          <CountryCodeMenuItem {...countryData} />
        </MenuItem>
      );
    }

    return Object.keys(countryOptions).map((k: string) => {
      const countryData = {
        ...countryOptions[k],
        countryAbrv: k,
      };

      return (
        <MenuItem value={k} key={k}>
          <CountryCodeMenuItem {...countryData} />
        </MenuItem>
      );
    });
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <FormControl
        sx={{
          width: {
            xs: '55%',
            sm: '25%',
          },
        }}>
        <Select
          value={countryCodeValue}
          disabled={Boolean(countryOptions[countryCodeValue])}
          onChange={(e) => {
            setCountryCodeValue(e.target.value);
            onCountryChange(e.target.value);
          }}>
          {menuItems()}
        </Select>
      </FormControl>
      <FormControl
        sx={{
          width: '100%',
          marginX: {
            xs: '0.5rem',
            sm: '1rem',
          },
        }}>
        <Input
          id="phoneText"
          placeholder={t('constant.typeYourAnswerHere') ?? ''}
          value={phoneValue}
          onChange={(e) => {
            setPhoneValue(e.target.value);
            onPhoneChange(e.target.value);
          }}
          type="tel"
          autoFocus
        />
      </FormControl>
    </Box>
  );
};
