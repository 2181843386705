import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { createContext } from 'use-context-selector';
import { LegacyAPI } from '../../services/legacy-api/api';
import { Job, JobStatus } from '../../types/job';
import {
  JobAction,
  JobContext,
  JobErrorPayload,
  JobLoadedPayload,
  JobLoadingPayload,
  JobState,
  Types,
} from './types';
import { setAppLocale } from '../../utils/common';

const initialJob: Job = {
  companyImageUrl: '',
  companyName: '',
  countryCode: '',
  description: '',
  title: '',
  jobImageUrl: '',
  jobCategoryTranslateKey: '',
  jobPositionTranslateKey: '',
  companyVerified: false,
  militaryRequirements: [],
  location: '',
  fare: false,
  freeMeal: false,
  healthInsurance: false,
  status: JobStatus.CLOSED,
  interviewLocation: 'Test Location Street. Test Location Avenue 123',
  languageCode: 'tr-TR',
};

const initialState: JobContext = {
  state: {
    job: initialJob,
    loading: false,
    uuid: '',
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
};
const jobStore = createContext<JobContext>(initialState);
const { Provider } = jobStore;

const JobStateProvider = ({ children }: { children: React.ReactNode }) => {
  const [shouldFetch, setShouldFetch] = useState(false);

  const [state, dispatch] = useReducer((state: JobState, action: JobAction) => {
    switch (action.type) {
      case Types.Loading: {
        const newState = {
          ...state,
          loading: true,
          uuid: (action.payload as JobLoadingPayload).jobUUID,
        };
        setShouldFetch((prev) => !prev);
        return newState;
      }
      case Types.Loaded: {
        const newState = {
          ...state,
          loading: false,
          job: (action.payload as JobLoadedPayload).job,
        };
        return newState;
      }
      case Types.Error: {
        const newState = {
          ...state,
          loading: false,
          error: (action.payload as JobErrorPayload).error,
        };
        return newState;
      }
      default:
        throw new Error();
    }
  }, initialState.state);

  const fetchJob = async (uuid: string): Promise<Job | undefined> => {
    if (!uuid) {
      return;
    }
    try {
      const api = new LegacyAPI();
      const result = await api.getJobWithUUID(uuid);

      return result;
    } catch (error) {
      dispatch({ type: Types.Error, payload: { error: (error as Error).message } });
    }
  };

  const onJobFetch = useCallback(async (uuid: string) => {
    const job = await fetchJob(uuid);
    if (job) {
      setAppLocale(job.languageCode.slice(0, 2).toLowerCase());
      dispatch({
        type: Types.Loaded,
        payload: { job },
      });
    }
  }, []);

  useEffect(() => {
    onJobFetch(state.uuid);
  }, [shouldFetch, onJobFetch, state.uuid]);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { jobStore, JobStateProvider };
