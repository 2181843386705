import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import { QuestionChoice } from '../../types/question';
import { Box } from '@mui/material';

function RadioGroupComponent({
  label,
  options,
  onChange,
  selectedChoiceId,
}: {
  label: string;
  options: QuestionChoice[];
  onChange: (value: number) => void;
  selectedChoiceId?: number;
}) {
  return (
    <FormGroup>
      <FormLabel component="legend" sx={{ marginBottom: 1, marginTop: 1 }}>
        {label}
      </FormLabel>
      <RadioGroup onChange={(e) => onChange(parseInt(e.target.value))}>
        {options &&
          options.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  paddingVertical: {
                    xs: '0.25rem',
                    sm: '0.5rem',
                  },
                  paddingLeft: '1rem',
                  backgroundColor:
                    item.id === selectedChoiceId ? 'primary.main[100]' : 'background.paper',
                  margin: '0.25rem',
                  borderRadius: '0.5rem',
                  border: '1px solid',
                }}>
                <FormControlLabel
                  control={<Radio value={item.id} checked={item.id === selectedChoiceId} />}
                  label={item.text}
                  value={item.id}
                />
              </Box>
            );
          })}
      </RadioGroup>
    </FormGroup>
  );
}

export default RadioGroupComponent;
