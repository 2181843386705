import { Box, Grid, Typography, Fab, Slide, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';
import { jobStore } from '../../store/job/job-store';
import { useContextSelector } from 'use-context-selector';
import { JobContext, Types } from '../../store/job/types';
import { Types as TypesOfUser } from '../../store/user/types';
import { useEffect } from 'react';
import { LoadingSpinner } from '../common/loading-spinner';
import { CenteredComponent } from '../common/centered-component';
import { DisplayedScreen } from '../../constants';
import ErrorPage from '../../pages/error-page';
import { useEnterListener } from '../../utils/common';
import { JobOppurtunityRow } from '../common/job-oppurtunity-row';
import { WorkTimePreferencesText } from '../common/work-time-preferences-text';
import VerifiedIcon from '@mui/icons-material/Verified';
import { JobStatus } from '../../types/job';
import { Helmet } from 'react-helmet';
import { UserContext } from '../../store/user/types';
import { userStore } from '../../store/user/user-store';
import { StaticGoogleMaps } from '../common/static-google-maps';

export const JobCard = ({
  uuid,
  displayed,
  setDisplayedIndex,
}: {
  uuid?: string;
  displayed: boolean;
  setDisplayedIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { t } = useTranslation();

  const dispatchToUserStore = useContextSelector<UserContext, UserContext['dispatch']>(
    userStore,
    (s) => s.dispatch,
  );

  const dispatch = useContextSelector<JobContext, JobContext['dispatch']>(
    jobStore,
    (s) => s.dispatch,
  );

  const job = useContextSelector<JobContext, JobContext['state']['job']>(
    jobStore,
    (s) => s.state.job,
  );

  const loading = useContextSelector<JobContext, JobContext['state']['loading']>(
    jobStore,
    (s) => s.state.loading,
  );

  const error = useContextSelector<JobContext, JobContext['state']['error']>(
    jobStore,
    (s) => s.state.error,
  );

  useEffect(() => {
    if (!uuid) {
      dispatch({ type: Types.Error, payload: { error: 'job.jobUUIDMissing' } });

      return;
    }

    dispatch({ type: Types.Loading, payload: { jobUUID: uuid } });
  }, []);

  useEffect(() => {
    if (job?.countryCode) {
      dispatchToUserStore({
        type: TypesOfUser.CountrySelected,
        payload: { country: job.countryCode },
      });
    }
  }, [job]);

  const handleSubmit = () => {
    if (uuid && job && job.status === JobStatus.ACTIVE) {
      setDisplayedIndex(DisplayedScreen.PhoneInput);
    }
  };

  useEnterListener(handleSubmit, [job]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    if (error === 'apiResponse.couldNotGetJob') {
      return <ErrorPage />;
    }
    return (
      <CenteredComponent>
        <div>{t(error)}</div>
      </CenteredComponent>
    );
  }

  return (
    <>
      <Helmet>
        <html lang={job?.languageCode.slice(0, 2).toLowerCase()} />
        <title>{job?.title}</title>
        <meta name="title" property="title" content={job?.title} />
        <meta name="title" property="og:title" content={job?.title} />
        <meta name="image" property="og:image" content={job?.jobImageUrl} />
        <meta name="description" property="description" content={job?.description} />
        <meta name="description" property="og:description" content={job?.description} />
      </Helmet>

      <Slide direction="up" in={displayed} mountOnEnter unmountOnExit>
        <Grid
          container
          sx={{
            paddingTop: {
              xs: '1vh',
              sm: '3vh',
            },
            paddingLeft: {
              xs: '1vh',
              sm: '3vh',
            },
            paddingRight: {
              xs: '1vh',
              sm: '3vh',
            },
            width: '100%',
          }}>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              padding: '2.5vh',
            }}>
            <Box
              sx={{
                width: '100%',
                marginBottom: '0.25rem',
              }}>
              <img
                src={job?.jobImageUrl}
                alt={job.title}
                style={{
                  objectFit: 'contain',
                  height: '30%',
                  width: '100%',
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                marginBottom: '0.25rem',
              }}>
              <Typography variant="body1" component="div" sx={{ marginRight: '0.25rem' }}>
                {job.companyName}
              </Typography>
              {job.companyVerified ? <VerifiedIcon color="success" fontSize="small" /> : null}
            </Box>

            <Typography variant="h6" component="div">
              {job.title}
            </Typography>

            <Divider
              sx={{
                marginY: '1rem',
              }}
            />
            <WorkTimePreferencesText job={job} />
            <JobOppurtunityRow job={job} />
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                whiteSpace: 'pre-line',
                marginTop: '1.5rem',
              }}>
              {job.description}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              padding: '2.5vh',
            }}>
            <Box
              sx={{
                width: '100%',
                height: {
                  sm: '90vh',
                  xs: '30vh',
                },
              }}>
              <StaticGoogleMaps lat={job.lat} lng={job.lng} />
            </Box>
          </Grid>
          <Box
            sx={{
              margin: '1rem',
              display: 'flex',
              justifyContent: 'center',
              position: 'fixed',
              bottom: '3vh',
              left: '50%',
              transform: {
                xs: 'translate(-66%, 0)',
                sm: 'translate(-50%, 0)',
              },
            }}>
            <Fab
              variant="extended"
              color="primary"
              onClick={handleSubmit}
              disabled={job.status !== JobStatus.ACTIVE}>
              <ArrowForwardIosTwoToneIcon sx={{ mr: 1 }} />
              <span>{t('job.apply')}</span>
            </Fab>
          </Box>
        </Grid>
      </Slide>
    </>
  );
};
