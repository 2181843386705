import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { QuestionWithAnswer } from '../../types/question';
import RadioGroup from '../common/radio-box-group';
import { useEffect, useState } from 'react';

type ChoiceAnswerQuestionProps = {
  question: QuestionWithAnswer;
  onChange: (value: string) => void;
};

const ChoiceAnswerQuestion = (props: ChoiceAnswerQuestionProps) => {
  const { question, onChange } = props;
  const [selectedChoiceId, setSelectedChoiceId] = useState<number | undefined>(undefined);

  const { t } = useTranslation();

  useEffect(() => {
    if (question) {
      setSelectedChoiceId(question.questionChoice?.find((c) => c.text === question.answer)?.id);
    }
  }, [question]);

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}>
      <Typography
        gutterBottom
        variant="h5"
        component="div"
        sx={{
          width: '100%',
          textAlign: 'start',
        }}>
        {question.text}
      </Typography>

      <Box
        sx={{
          maxWidth: '100%',
          minWidth: {
            xs: '60%',
            sm: '40%',
          },
          alignSelf: 'start',
        }}>
        <RadioGroup
          label={question.description ?? t('question.selectAnswer')}
          options={question.questionChoice ?? []}
          selectedChoiceId={selectedChoiceId}
          onChange={(value) => {
            onChange(question.questionChoice?.find((c) => c.id === value)?.text ?? '');
            setSelectedChoiceId(value);
          }}
        />
      </Box>
    </Box>
  );
};

export default ChoiceAnswerQuestion;
