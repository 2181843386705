import { Box, Fab, Alert, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import { MuiPhone } from '../common/mui-phone';
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';
import { Types, UserContext } from '../../store/user/types';
import { userStore } from '../../store/user/user-store';
import { DelayedSlide } from '../common/delayed-slide';
import { DisplayedScreen } from '../../constants';
import { useEnterListener } from '../../utils/common';
import { JobContext } from '../../store/job/types';
import { jobStore } from '../../store/job/job-store';

export const PhoneCheckComponent = ({
  displayed,
  setDisplayedIndex,
}: {
  displayed: boolean;
  setDisplayedIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { t } = useTranslation();

  const dispatch = useContextSelector<UserContext, UserContext['dispatch']>(
    userStore,
    (s) => s.dispatch,
  );

  const loading = useContextSelector<UserContext, UserContext['state']['loading']>(
    userStore,
    (s) => s.state.loading,
  );

  const phoneError = useContextSelector<UserContext, UserContext['state']['phoneError']>(
    userStore,
    (s) => s.state.phoneError,
  );

  const phoneSubmissionError = useContextSelector<
    UserContext,
    UserContext['state']['phoneSubmissionError']
  >(userStore, (s) => s.state.phoneSubmissionError);

  const showVerification = useContextSelector<
    UserContext,
    UserContext['state']['showVerification']
  >(userStore, (s) => s.state.showVerification);

  const jobCountryCode = useContextSelector<JobContext, JobContext['state']['job']['countryCode']>(
    jobStore,
    (s) => s.state.job.countryCode,
  );

  const showEmailCheck = useContextSelector<UserContext, UserContext['state']['showEmailCheck']>(
    userStore,
    (s) => s.state.showEmailCheck,
  );

  const onPhoneChange = (phoneNumber: string) => {
    dispatch({ type: Types.PhoneEntered, payload: { phoneNumber } });
  };

  const onCountryChange = (country: string) => {
    dispatch({ type: Types.CountrySelected, payload: { country } });
  };

  const handleSubmit = () => {
    dispatch({ type: Types.PhoneSubmitted, payload: {} });
  };

  useEffect(() => {
    if (showVerification) {
      setDisplayedIndex(DisplayedScreen.VerificationCodeInput);
    }
  }, [showVerification]);

  useEffect(() => {
    if (showEmailCheck) {
      setDisplayedIndex(DisplayedScreen.EmailInput);
    }
  }, [showEmailCheck]);

  useEnterListener(handleSubmit);

  return (
    <DelayedSlide iN={displayed} delay={800}>
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: {
            xs: '1vh',
            sm: '5vh',
          },
        }}>
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '50%',
            },
          }}>
          {phoneError && (
            <Alert sx={{ marginY: '1rem' }} severity="error">
              {t(phoneError)}
            </Alert>
          )}
          {phoneSubmissionError && (
            <Alert sx={{ marginY: '1rem' }} severity="error">
              {t(phoneSubmissionError)}
            </Alert>
          )}

          <Typography
            variant="h5"
            component="div"
            sx={{
              marginBottom: '2rem',
            }}>
            {t('phoneCheck.pleaseEnterPhone')}
          </Typography>

          <MuiPhone
            onPhoneChange={onPhoneChange}
            onCountryChange={onCountryChange}
            initialCountryCode={jobCountryCode}
          />
          <Box
            sx={{
              margin: '1rem',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Fab variant="extended" color="primary" onClick={handleSubmit} disabled={loading}>
              {loading ? (
                <div>
                  <span>{t('common.loading')}</span>
                </div>
              ) : (
                <>
                  <ArrowForwardIosTwoToneIcon sx={{ mr: 1 }} />
                  <span>{t('phoneEmail.continue')}</span>
                </>
              )}
            </Fab>
          </Box>
        </Box>
      </Box>
    </DelayedSlide>
  );
};
