export type Job = {
  companyImageUrl?: string;
  companyName: string;
  countryCode: string;
  languageCode: string;
  description: string;
  title: string;
  jobImageUrl?: string;
  jobCategoryTranslateKey: string;
  jobPositionTranslateKey?: string;
  workTimePreferenceFullTime?: boolean;
  workTimePreferencePartTime?: boolean;
  workTimePreferenceSeasonal?: boolean;
  companyVerified: boolean;
  militaryRequirements?: MilitaryStatus[];
  location?: string;
  fare: boolean;
  freeMeal: boolean;
  healthInsurance: boolean;
  status: JobStatus;
  lat?: number;
  lng?: number;
  interviewLocation?: string;
};

export enum JobStatus {
  ACTIVE = 'active',
  DELETED = 'deleted',
  CLOSED = 'closed',
}

export enum MilitaryStatus {
  WAITING = 1,
  DELAYED = 2,
  DONE = 3,
}
