import {
  Box,
  Modal,
  SxProps,
  Typography,
  Theme,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Button,
  Alert,
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { JobExperience, JobExperienceRequest } from '../../types/jobExperience';
import { LegacyAPI } from '../../services/legacy-api/api';
import { JobPosition } from '../../types/job-position';

const modalStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '90vw',
    sm: '50vw',
  },
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderColor: 'primary.main',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: 10,
};

type JobPositionOption = {
  id: number;
  label: string;
};

const JobExperienceModal = ({
  modalOpen,
  setModalOpen,
  jobExperiences,
  setJobExperiences,
  jobExperience,
  setJobExperience,
  api,
}: {
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  jobExperiences: JobExperience[];
  setJobExperiences: Dispatch<SetStateAction<JobExperience[]>>;
  jobExperience: JobExperience | undefined;
  setJobExperience: Dispatch<SetStateAction<JobExperience | undefined>>;
  api: LegacyAPI;
}) => {
  const { t } = useTranslation();
  const [companyName, setCompanyName] = useState('');
  const [duration, setDuration] = useState('');
  const [isLast, setIsLast] = useState(false);
  const [positionId, setPositionId] = useState(0);
  const [jobPositions, setJobPositions] = useState<JobPositionOption[]>([]);
  const [selectedJobPosition, setSelectedJobPosition] = useState<JobPositionOption | undefined>(
    undefined,
  );
  const [jobPositionSearchQuery, setJobPositionSearchQuery] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const getJobPositions = async () => {
    const jobPositionsData = await api.getJobPositions(jobPositionSearchQuery);
    const mappedJobPositions = jobPositionsData.map((jobPosition) =>
      jobPositionMapper(jobPosition),
    );
    setJobPositions(mappedJobPositions);
  };

  const jobPositionMapper = (jobPosition: JobPosition): JobPositionOption => {
    return {
      id: jobPosition.id,
      label: t(jobPosition.translate_key),
    };
  };

  const onJobPositionChange = (jobPositionInput: JobPositionOption | null) => {
    if (jobPositionInput) {
      setSelectedJobPosition(jobPositionInput);
      setPositionId(jobPositionInput.id);
      return;
    }

    setSelectedJobPosition(undefined);
    setPositionId(0);
  };

  const onSubmit = async () => {
    setLoading(true);
    const jobExperienceRequest: JobExperienceRequest = {
      company_name: companyName,
      job_position: positionId,
      duration: +duration,
      is_last: isLast,
    };

    if (companyName === '' || positionId === 0 || duration === '' || inputValue === '') {
      setError('jobExperience.allFieldsRequired');
      setLoading(false);
      return;
    }

    if (jobExperience) {
      try {
        const jobExperienceResponse = await api.updateJobExperience(
          jobExperience.id,
          jobExperienceRequest,
        );
        const updatedJobExperiencesWithoutResponse = jobExperiences.filter(
          (jobExperienceElement) => jobExperienceElement.id !== jobExperienceResponse.id,
        );
        setJobExperiences([...updatedJobExperiencesWithoutResponse, jobExperienceResponse]);
        setError('');
        setSuccess('jobExperience.jobExperienceUpdated');
        setLoading(false);
      } catch (e) {
        setError('common.somethingWentWrong');
        setSuccess('');
        setLoading(false);
      }
      return;
    }

    try {
      const jobExperienceResponse = await api.addJobExperience(jobExperienceRequest);
      setJobExperiences([...jobExperiences, jobExperienceResponse]);
      setError('');
      setSuccess('jobExperience.jobExperienceAdded');
      setLoading(false);
    } catch (e) {
      setError('common.somethingWentWrong');
      setSuccess('');
      setLoading(false);
    }
  };

  const onDelete = async () => {
    setLoading(true);
    if (!jobExperience) {
      setError('jobExperience.jobExperienceIsNotExist');
      setSuccess('');
      setLoading(false);
      return;
    }
    try {
      await api.deleteJobExperience(jobExperience.id);
      const updatedJobExperiences = jobExperiences.filter(
        (jobExperienceElement) => jobExperienceElement.id !== jobExperience.id,
      );
      setJobExperiences(updatedJobExperiences);
      setJobExperience(undefined);
      setLoading(false);
      onModalClose();
    } catch (e) {
      setError('common.somethingWentWrong');
      setSuccess('');
      setLoading(false);
    }
  };

  const onModalClose = () => {
    resetModalValues();
    setModalOpen(false);
  };

  const resetModalValues = () => {
    setSelectedJobPosition(undefined);
    setPositionId(0);
    setCompanyName('');
    setDuration('');
    setIsLast(false);
    setJobPositionSearchQuery('');
    setInputValue('');
    setError('');
    setSuccess('');
  };

  useEffect(() => {
    getJobPositions();
  }, [jobPositionSearchQuery]);

  useEffect(() => {
    if (jobExperience && jobExperience.job_position) {
      const mappedJobPosition = jobPositionMapper(jobExperience.job_position);
      setSelectedJobPosition(mappedJobPosition);
      setInputValue(mappedJobPosition.label);
      setPositionId(jobExperience.job_position.id);
      setCompanyName(jobExperience.company_name);
      setDuration(jobExperience.duration.toString());
      setIsLast(jobExperience.is_last);
      setJobPositionSearchQuery(jobExperience.position);
    }
  }, [jobExperience]);

  return (
    <Modal
      open={modalOpen}
      onClose={() => onModalClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {jobExperience
            ? t('jobExperience.editJobExperience')
            : t('jobExperience.addJobExperience')}
        </Typography>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{ width: '80%' }}>
          {error && <Alert severity="error">{t(error)}</Alert>}
          {success && <Alert severity="success">{t(success)}</Alert>}

          <TextField
            fullWidth
            sx={{ marginY: '2rem' }}
            label={t('jobExperience.companyName')}
            value={companyName}
            onChange={(e) => {
              setCompanyName(e.target.value);
            }}
            type="text"
            variant="standard"
          />
          <Autocomplete
            id="combo-box-job-positions"
            filterOptions={(x) => x}
            options={jobPositions}
            freeSolo
            autoComplete
            includeInputInList
            filterSelectedOptions
            fullWidth
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            value={selectedJobPosition}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                return;
              }
              onJobPositionChange(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setJobPositionSearchQuery(newInputValue);
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label={t('jobExperience.jobPositions')} />
            )}
          />
          <TextField
            fullWidth
            sx={{ marginY: '2rem' }}
            label={t('jobExperience.duration')}
            value={duration}
            onChange={(e) => {
              setDuration(e.target.value);
              //TODO:burayi duzelt en son request e gonderirken number a cevir
            }}
            type="number"
            variant="standard"
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  value={isLast}
                  onChange={(event) => {
                    setIsLast(event.target.checked);
                  }}
                />
              }
              label={t('jobExperience.isLast')}
            />
          </FormGroup>
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <Button
              sx={{ color: 'gray', border: 1, borderColor: 'gray', fontWeight: 'normal' }}
              disabled={loading}
              onClick={() => onSubmit()}>
              {jobExperience ? t('jobExperience.edit') : t('jobExperience.add')}
            </Button>
            {jobExperience && (
              <Button
                sx={{ color: 'gray', border: 1, borderColor: 'gray', fontWeight: 'normal' }}
                disabled={loading}
                onClick={() => onDelete()}>
                {t('jobExperience.delete')}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default JobExperienceModal;
