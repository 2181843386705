import { Box, Button, Modal, SxProps, Theme, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@mui/icons-material/Done';

const modalStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '90vw',
    sm: '50vw',
  },
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderColor: 'primary.main',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: 10,
};

export const DriverLicenceInputOptionsModal = ({
  modalOpen,
  setModalOpen,
  selectedDrivingLicences,
  onDrivingLicencesSelect,
  drivingLicences,
}: {
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  selectedDrivingLicences: string[];
  onDrivingLicencesSelect: (drivingLicenceInput: string) => void;
  drivingLicences: string[];
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {t('register.pickDriverLicences')}
        </Typography>
        <Box
          sx={{
            width: '80%',
            padding: '1rem',
            display: 'flex',
            gap: '1rem',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {drivingLicences
            .filter((licence) => licence !== 'Ehliyetim Yok')
            .map((option) => {
              const isSelected = selectedDrivingLicences.includes(option);
              return (
                <Button
                  endIcon={isSelected && <DoneIcon />}
                  onClick={() => onDrivingLicencesSelect(option)}
                  sx={{
                    color: isSelected ? 'white' : 'darkgray',
                    backgroundColor: isSelected ? 'darkcyan' : '',
                    opacity: isSelected ? 0.8 : 1,
                    border: isSelected ? 0 : 1,
                    borderColor: 'gray',
                    whiteSpace: 'nowrap',
                    fontWeight: 'normal',
                    flex: '1 0 auto',
                  }}
                  key={option}>
                  {t(option)}
                </Button>
              );
            })}
        </Box>
      </Box>
    </Modal>
  );
};
