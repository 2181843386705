import moment from 'moment';
import React, { useReducer } from 'react';
import { createContext } from 'use-context-selector';
import { AppointmentAPI } from '../../services/appointment-service/api';
import { JobAPI } from '../../services/job-service/api';
import { LegacyAPI } from '../../services/legacy-api/api';
import { MomentDate } from '../../types/appointment-slot';
import { QuestionType, QuestionWithAnswer, RelationOptions } from '../../types/question';
import {
  AppointmentsLoadedPayload,
  CompletePreQuestionProcessesPayload,
  DisplayQuestionPayload,
  ErrorPayload,
  JobApplicationCompletedPayload,
  QuestionFormAction,
  QuestionFormContext,
  QuestionFormState,
  QuestionsLoadedPayload,
  SelectAppointmentSlotDatePayload,
  SelectAppointmentSlotHourPayload,
  SetAnswerPayload,
  SetCompanyIdPayload,
  SetCompanyPhonePayload,
  SetJobIdPayload,
  SetWorkerIdPayload,
  Types,
} from './types';

const initialState: QuestionFormContext = {
  state: {
    questions: [
      {
        id: 1,
        text: 'What is your name?',
        createdAt: new Date(),
        description: 'xyz',
        jobId: 1,
        order: 1,
        questionType: QuestionType.TEXT,
        questionChoice: [],
        relation: RelationOptions.NONE,
        updatedAt: new Date(),
      },
    ],
    jobUUID: '',
    loading: false,
    finished: false,
    appointmentDateSelected: false,
    canGoToNextQuestion: false,
    canGoToPreviousQuestion: false,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
};
const formStore = createContext<QuestionFormContext>(initialState);
const { Provider } = formStore;

const FormStateProvider = ({ children }: { children: React.ReactNode }) => {
  const fetchQuestions = async (
    jobId: number,
    applicationId: number,
  ): Promise<QuestionWithAnswer[] | undefined> => {
    try {
      const api = new JobAPI();
      const questions = await api.getQuestionsByJobIdAndApplicationId(jobId, applicationId);
      return questions
        .map<QuestionWithAnswer>((q) => ({
          ...q,
          answer: undefined,
        }))
        .sort((a, b) => a.order - b.order);
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: Types.LoadingError, payload: { error: e.message } });
      }

      dispatch({ type: Types.LoadingError, payload: { error: 'questions.couldNotLoad' } });
    }
  };

  const completeJobApplication = async (jobUUID: string): Promise<void> => {
    try {
      const legacyApi = new LegacyAPI();

      const jobId = await legacyApi.getJobIdWithUUID(jobUUID);

      if (!jobId) {
        dispatch({
          type: Types.LoadingError,
          payload: { error: 'application.couldNotApplyToJob' },
        });
        return;
      }

      dispatch({ type: Types.SetJobId, payload: { jobId } });

      const application = await legacyApi.applyToJob(jobId);

      if (!application) {
        dispatch({
          type: Types.LoadingError,
          payload: { error: 'application.couldNotApplyToJob' },
        });
        return;
      }

      const { id: companyId, phone: companyPhone } = application.job.user;
      const { id: workerId } = application.user;

      if (!companyId) {
        dispatch({
          type: Types.LoadingError,
          payload: { error: 'application.couldNotApplyToJob' },
        });
        return;
      }

      if (!workerId) {
        dispatch({
          type: Types.LoadingError,
          payload: { error: 'application.couldNotApplyToJob' },
        });
        return;
      }

      dispatch({ type: Types.SetWorkerId, payload: { workerId } });

      dispatch({ type: Types.SetCompanyId, payload: { companyId } });

      dispatch({ type: Types.SetCompanyPhone, payload: { companyPhone } });

      dispatch({
        type: Types.JobApplicationCompleted,
        payload: { applicationId: application.id, application: application },
      });
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: Types.LoadingError, payload: { error: e.message } });
        return;
      }
      dispatch({ type: Types.LoadingError, payload: { error: 'common.somethingWentWrong' } });
    }
  };

  const answerQuestion = async (
    questionId: number,
    applicationId: number,
    text: string,
    lastQuestion = false,
  ): Promise<void> => {
    try {
      const api = new JobAPI();

      await api.answerQuestion(questionId, text, applicationId);

      if (lastQuestion) {
        dispatch({ type: Types.LastQuestionAnswered, payload: {} });
      }

      return;
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: Types.AnsweringError, payload: { error: e.message } });
        return;
      }
    }

    dispatch({ type: Types.AnsweringError, payload: { error: 'questions.couldNotAnswer' } });
  };

  const fetchQuestionsCallback = async (jobId: number, applicationId: number) => {
    const questions = await fetchQuestions(jobId, applicationId);

    if (!questions) {
      dispatch({ type: Types.LoadingError, payload: { error: 'questions.couldNotLoad' } });
      return;
    }

    dispatch({
      type: Types.Loaded,
      payload: { questions },
    });
  };

  const confirmAppointmentSlot = async (
    start: string,
    end: string,
    date: MomentDate,
    companyId: number,
    workerId: number,
    applicationId: number,
    jobId: number,
  ) => {
    const startAt = `${date.format('YYYY-MM-DD')} ${start}:00`;
    const endAt = `${date.format('YYYY-MM-DD')} ${end}:00`;

    const api = new AppointmentAPI();

    try {
      await api.createApplicationAppointment({
        workerId: workerId,
        companyId: companyId,
        applicationId: applicationId,
        jobId: jobId,
        startAt: startAt,
        endAt: endAt,
      });

      dispatch({ type: Types.AppointmentConfirmed, payload: {} });
      return;
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: Types.AppointmentError, payload: { error: e.message } });
        return;
      }
    }

    dispatch({ type: Types.AppointmentError, payload: {} });
  };

  const fetchAppointmentSlots = async (jobId: number, workerId: number, companyId: number) => {
    try {
      const api = new AppointmentAPI();
      const slots = await api.getSuitableAppointmentSlots(jobId, workerId, companyId);

      if (!slots || slots?.length === 0) {
        dispatch({ type: Types.Finished, payload: {} });
        return;
      }

      dispatch({
        type: Types.AppointmentsLoaded,
        payload: { slots },
      });
    } catch (e) {
      if (e instanceof Error) {
        dispatch({ type: Types.LoadingError, payload: { error: e.message } });
        return;
      }

      dispatch({ type: Types.LoadingError, payload: { error: 'common.somethingWentWrong' } });
    }
  };

  const [state, dispatch] = useReducer((state: QuestionFormState, action: QuestionFormAction) => {
    let newState: QuestionFormState;
    switch (action.type) {
      case Types.Finished: {
        newState = {
          ...state,
          finished: true,
          loading: false,
        };

        return newState;
      }
      case Types.LoadingError: {
        newState = {
          ...state,
          loadingError: (action.payload as ErrorPayload).error,
          loading: false,
        };

        return newState;
      }
      case Types.AppointmentError: {
        newState = {
          ...state,
          appointmentError: (action.payload as ErrorPayload).error,
          loading: false,
        };

        return newState;
      }
      case Types.SetWorkerId: {
        newState = {
          ...state,
          workerId: (action.payload as SetWorkerIdPayload).workerId,
        };

        return newState;
      }
      case Types.AppointmentConfirmed: {
        newState = {
          ...state,
          finished: true,
          loading: false,
        };

        return newState;
      }
      case Types.ConfirmAppointmentSlot: {
        newState = {
          ...state,
          loading: true,
        };

        if (
          !state.selectedAppointmentSlot ||
          !state.selectedAppointmentSlot?.endAt ||
          !state.selectedAppointmentSlot?.startAt ||
          !state.companyId ||
          !state.workerId ||
          !state.applicationId ||
          !state.jobId
        ) {
          newState = {
            ...state,
            appointmentError: 'common.somethingWentWrong',
            loading: false,
          };

          return newState;
        }

        confirmAppointmentSlot(
          state.selectedAppointmentSlot.startAt,
          state.selectedAppointmentSlot.endAt,
          state.selectedAppointmentSlot.date,
          state.companyId,
          state.workerId,
          state.applicationId,
          state.jobId,
        );

        return newState;
      }
      case Types.SelectAppointmentSlotHour: {
        if (!state.selectedAppointmentSlot?.date) {
          newState = {
            ...state,
            appointmentError: 'appointment.selectDateFirst',
          };

          return newState;
        }
        const { startAt, endAt } = action.payload as SelectAppointmentSlotHourPayload;
        const { date } = state.selectedAppointmentSlot;
        newState = {
          ...state,
          selectedAppointmentSlot: {
            date,
            startAt,
            endAt,
          },
        };

        return newState;
      }
      case Types.SelectAppointmentSlotDate: {
        const { date } = action.payload as SelectAppointmentSlotDatePayload;
        newState = {
          ...state,
          appointmentDateSelected: true,
          selectedAppointmentSlot: { date },
        };

        return newState;
      }
      case Types.SetCompanyId: {
        const { companyId } = action.payload as SetCompanyIdPayload;

        newState = {
          ...state,
          companyId,
        };

        return newState;
      }
      case Types.SetCompanyPhone: {
        const { companyPhone } = action.payload as SetCompanyPhonePayload;

        newState = {
          ...state,
          companyPhone,
        };

        return newState;
      }
      case Types.AppointmentsLoaded: {
        const { slots } = action.payload as AppointmentsLoadedPayload;

        newState = {
          ...state,
          appointmentSlots: slots.map((slot) => ({
            ...slot,
            date: moment(slot.date),
          })),
          loading: false,
          showAppointmentSlots: true,
        };

        return newState;
      }
      case Types.SetJobId: {
        const { jobId } = action.payload as SetJobIdPayload;

        newState = {
          ...state,
          jobId,
        };

        return newState;
      }
      case Types.SetLoading: {
        newState = {
          ...state,
          loading: true,
        };

        return newState;
      }
      case Types.CompletePreQuestionProcesses: {
        const { uuid } = action.payload as CompletePreQuestionProcessesPayload;

        completeJobApplication(uuid);

        newState = {
          ...state,
          jobUUID: uuid,
        };

        return newState;
      }

      case Types.LastQuestionAnswered: {
        if (!state.jobId || !state.workerId || !state.companyId) {
          newState = {
            ...state,
            loadingError: 'common.somethingWentWrong',
            loading: false,
          };

          return newState;
        }

        fetchAppointmentSlots(state.jobId, state.workerId, state.companyId);

        newState = {
          ...state,
          loading: true,
          answeringError: undefined,
          loadingError: undefined,
        };

        return newState;
      }
      case Types.Loading: {
        if (!state.jobId || !state.applicationId) {
          newState = {
            ...state,
            loadingError: 'questions.couldNotLoad',
            loading: false,
          };

          return newState;
        }

        newState = {
          ...state,
          loading: true,
        };

        fetchQuestionsCallback(state.jobId, state.applicationId);

        return newState;
      }
      case Types.Loaded: {
        const questions = (action.payload as QuestionsLoadedPayload).questions;

        //if there are not questions, it means either the user completed the questions or there are no questions for the job
        //in both cases, load appointments
        if (questions.length === 0) {
          if (!state.jobId || !state.workerId || !state.companyId) {
            newState = {
              ...state,
              loadingError: 'common.somethingWentWrong',
              loading: false,
            };

            return newState;
          }

          newState = {
            ...state,
            loading: true,
          };

          fetchAppointmentSlots(state.jobId, state.workerId, state.companyId);

          return newState;
        }

        //if there are questions left and the questions are marked as completed, it means that the user is not eligible to complete the application
        if (
          questions.length > 0 &&
          state.application &&
          state.application.is_questions_completed === true
        ) {
          newState = {
            ...state,
            loading: false,
            showSorry: true,
          };

          return newState;
        }

        //if there are questions left and the questions are not marked as completed, it means that the user has not completed the questions yet
        const loadedQuestions = (action.payload as QuestionsLoadedPayload).questions;
        newState = {
          ...state,
          loading: false,
          questions: loadedQuestions,
          displayedQuestion: loadedQuestions[0],
          canGoToNextQuestion: Boolean(loadedQuestions[1]),
        };

        return newState;
      }
      case Types.DisplayQuestion: {
        const indexToAdd = (action.payload as DisplayQuestionPayload).next ? 1 : -1;
        newState = {
          ...state,
          displayedQuestion:
            state.questions[
              state.questions.findIndex((q) => q.id === state.displayedQuestion?.id) + indexToAdd
            ],
        };
        return newState;
      }
      case Types.JobApplicationCompleted: {
        const payload = action.payload as JobApplicationCompletedPayload;
        newState = {
          ...state,
          applicationId: payload.applicationId,
          application: payload.application,
        };
        return newState;
      }
      case Types.PreviousQuestion: {
        const index = state.questions.findIndex((q) => q.id === state.displayedQuestion?.id);

        const previousQuestion = state.questions[index - 1];

        if (previousQuestion) {
          newState = {
            ...state,
            displayedQuestion: previousQuestion,
            canGoToPreviousQuestion: index - 1 !== 0,
            canGoToNextQuestion: true,
            answeringError: undefined,
          };

          return newState;
        }

        return state;
      }
      case Types.NextQuestion: {
        if (!state.displayedQuestion) {
          return state;
        }

        if (!state.displayedQuestion.answer) {
          newState = {
            ...state,
            answeringError:
              state.displayedQuestion.questionType === QuestionType.CHOICE
                ? 'questions.pleaseSelectAnAnswer'
                : 'questions.pleaseEnterAnAnswer',
          };

          return newState;
        }

        if (!state.displayedQuestion.synced) {
          if (!state.applicationId) {
            newState = {
              ...state,
              loading: false,
              answeringError: 'common.somethingWentWrong',
            };

            return newState;
          }

          const { id: questionId, answer } = state.displayedQuestion;

          const choice = state.displayedQuestion?.questionChoice?.find((c) => c.text === answer);

          const isContinueSelected = choice?.isContinue;

          if (!answer || (answer && answer.length === 0)) {
            newState = {
              ...state,
              loading: false,
              answeringError: choice
                ? 'questions.pleaseSelectAnAnswer'
                : 'questions.pleaseEnterAnAnswer',
            };

            return newState;
          }

          if (choice && !isContinueSelected) {
            answerQuestion(questionId, state.applicationId, answer);

            newState = {
              ...state,
              loading: false,
              showSorry: true,
            };

            return newState;
          }

          const questions = state.questions.map((q) =>
            q.id === questionId ? { ...q, answer } : q,
          );

          const currentIndex = questions.findIndex((q) => q.id === questionId);

          if (currentIndex + 1 >= questions.length) {
            answerQuestion(questionId, state.applicationId, answer, true);

            newState = {
              ...state,
              questions,
            };

            return newState;
          }

          const nextQuestion = questions[currentIndex + 1];

          const currentQuestion = questions[currentIndex];

          currentQuestion.synced = true;

          questions[currentIndex] = currentQuestion;

          newState = {
            ...state,
            questions,
            displayedQuestion: nextQuestion,
            answeringError: undefined,
            loadingError: undefined,
            canGoToPreviousQuestion: true,
          };

          answerQuestion(questionId, state.applicationId, answer);

          return newState;
        }

        const index = state.questions.findIndex((q) => q.id === state.displayedQuestion?.id);

        const nextQuestion =
          index < state.questions.length ? state.questions[index + 1] : undefined;

        if (nextQuestion) {
          newState = {
            ...state,
            displayedQuestion: nextQuestion,
            canGoToNextQuestion: index + 1 !== state.questions.length - 1,
            canGoToPreviousQuestion: true,
            answeringError: undefined,
          };

          return newState;
        }

        return state;
      }
      case Types.AnsweringError: {
        const currentIndex = state.questions.findIndex((q) => q.id === state.displayedQuestion?.id);

        const currentQuestion = state.questions[currentIndex];

        currentQuestion.synced = false;

        const questions = state.questions;

        questions[currentIndex] = currentQuestion;

        newState = {
          ...state,
          loading: false,
          answeringError: (action.payload as ErrorPayload).error,
          questions,
        };

        return newState;
      }
      case Types.SetAnswer: {
        if (!state.displayedQuestion) {
          return state;
        }

        const { answer } = action.payload as SetAnswerPayload;

        const displayedQuestion = state.displayedQuestion;
        displayedQuestion.answer = answer;

        const questions = state.questions.map((q) =>
          q.id === displayedQuestion.id ? displayedQuestion : q,
        );

        newState = {
          ...state,
          displayedQuestion,
          questions,
          answeringError: undefined,
        };

        return newState;
      }
      default: {
        return state;
      }
    }
  }, initialState.state);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { formStore, FormStateProvider };
