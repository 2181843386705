import { Box, FormControl, Input, InputLabel } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useState } from 'react';

export const MuiEmail = ({ onEmailChange }: { onEmailChange: (email: string) => void }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <FormControl fullWidth sx={{ marginY: '2rem' }}>
        <InputLabel htmlFor="emailText" className={email.trim() !== '' ? 'hasValue' : ''}>
          {t('common.email')}
        </InputLabel>
        <Input
          autoFocus
          id="emailText"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            onEmailChange(e.target.value);
          }}
          type="email"
        />
      </FormControl>
    </Box>
  );
};
