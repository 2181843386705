import { Box, Button, CircularProgress, Fab, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useContextSelector } from 'use-context-selector';
import { DisplayedScreen } from '../../constants';
import { formStore } from '../../store/form/form-store';
import { QuestionFormContext, Types } from '../../store/form/types';
import { UserContext } from '../../store/user/types';
import { userStore } from '../../store/user/user-store';
import { QuestionType, QuestionWithAnswer } from '../../types/question';
import { DelayedSlide } from '../common/delayed-slide';
import OnCardError from '../common/error/on-card-error';
import ChoiceAnswerQuestion from './choice-answer-question';
import OpenAnswerQuestion from './open-answer-question';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';

const QuestionForm = ({
  displayed,
  setDisplayedScreen,
}: {
  displayed: boolean;
  setDisplayedScreen: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { t } = useTranslation();

  const { uuid } = useParams();

  const showQuestions = useContextSelector<UserContext, boolean>(
    userStore,
    (s) => s.state.showQuestions,
  );

  const dispatch = useContextSelector<QuestionFormContext, QuestionFormContext['dispatch']>(
    formStore,
    (s) => s.dispatch,
  );

  const applicationId = useContextSelector<QuestionFormContext, number | undefined>(
    formStore,
    (s) => s.state.applicationId,
  );

  useEffect(() => {
    if (showQuestions) {
      if (!uuid) {
        dispatch({ type: Types.LoadingError, payload: { error: 'questions.couldNotLoad' } });
        return;
      }

      dispatch({ type: Types.SetLoading, payload: {} });

      dispatch({ type: Types.CompletePreQuestionProcesses, payload: { uuid } });
    }
  }, []);

  useEffect(() => {
    if (applicationId) {
      dispatch({ type: Types.Loading, payload: {} });
    }
  }, [applicationId]);

  const loading = useContextSelector<QuestionFormContext, boolean>(
    formStore,
    (s) => s.state.loading,
  );

  const displayedQuestion = useContextSelector<QuestionFormContext, QuestionWithAnswer | undefined>(
    formStore,
    (s) => s.state.displayedQuestion,
  );

  const loadingError = useContextSelector<QuestionFormContext, string | undefined>(
    formStore,
    (s) => s.state.loadingError,
  );

  const answeringError = useContextSelector<QuestionFormContext, string | undefined>(
    formStore,
    (s) => s.state.answeringError,
  );

  const finished = useContextSelector<QuestionFormContext, boolean>(
    formStore,
    (s) => s.state.finished,
  );

  const showSorry = useContextSelector<QuestionFormContext, boolean | undefined>(
    formStore,
    (s) => s.state.showSorry,
  );

  const showAppointmentSlots = useContextSelector<QuestionFormContext, boolean | undefined>(
    formStore,
    (s) => s.state.showAppointmentSlots,
  );

  const canGoToNextQuestion = useContextSelector<QuestionFormContext, boolean>(
    formStore,
    (s) => s.state.canGoToNextQuestion,
  );

  const canGoToPreviousQuestion = useContextSelector<QuestionFormContext, boolean>(
    formStore,
    (s) => s.state.canGoToPreviousQuestion,
  );

  useEffect(() => {
    if (showAppointmentSlots) {
      setDisplayedScreen(DisplayedScreen.AppointmentSlots);
    }
  }, [showAppointmentSlots]);

  useEffect(() => {
    if (finished) {
      setDisplayedScreen(DisplayedScreen.Finished);
    }
  }, [finished]);

  useEffect(() => {
    if (showSorry) {
      setDisplayedScreen(DisplayedScreen.Sorry);
    }
  }, [showSorry]);

  const onAnswer = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch({ type: Types.NextQuestion, payload: {} });
  };

  const handleChange = (value: string) => {
    dispatch({ type: Types.SetAnswer, payload: { answer: value } });
  };

  const questionChangeButtons = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          bottom: '2vh',
          right: '2vh',
        }}>
        <Tooltip title={t('questions.previousQuestion') ?? ''}>
          <span>
            <Button
              onClick={(e) => {
                e.preventDefault();
                dispatch({ type: Types.PreviousQuestion, payload: {} });
              }}
              variant="contained"
              disabled={!canGoToPreviousQuestion}
              sx={{
                marginRight: '0.25rem',
              }}>
              <ExpandLessIcon
                sx={{
                  color: 'white',
                }}
              />
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={t('questions.nextQuestion') ?? ''}>
          <span>
            <Button
              variant="contained"
              disabled={!canGoToNextQuestion}
              onClick={(e) => {
                e.preventDefault();
                dispatch({ type: Types.NextQuestion, payload: {} });
              }}>
              <ExpandMoreIcon
                sx={{
                  color: 'white',
                }}
              />
            </Button>
          </span>
        </Tooltip>
      </Box>
    );
  };

  return (
    <DelayedSlide iN={displayed} delay={800}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          overflow: 'hidden',
          padding: '1rem',
        }}>
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '50%',
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <OnCardError error={loadingError} fromTop />
          <OnCardError error={answeringError} fromTop />

          {loading && !loadingError ? (
            <CircularProgress color="primary" sx={{ minHeight: 1 }} />
          ) : displayedQuestion ? (
            <>
              {displayedQuestion.questionType === QuestionType.TEXT ? (
                <OpenAnswerQuestion question={displayedQuestion} onChange={handleChange} />
              ) : (
                <ChoiceAnswerQuestion question={displayedQuestion} onChange={handleChange} />
              )}
              <Fab variant="extended" color="primary" onClick={onAnswer} sx={{ marginTop: '2rem' }}>
                <ArrowForwardIosTwoToneIcon sx={{ mr: 1 }} />
                <span>{t('questions.next')}</span>
              </Fab>
              {questionChangeButtons()}
            </>
          ) : (
            <OnCardError error={'common.somethingWentWrong'} />
          )}
        </Box>
      </Box>
    </DelayedSlide>
  );
};

export default QuestionForm;
