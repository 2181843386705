import { Dispatch } from 'react';
import { MomentDate } from '../../types/appointment-slot';
import { Gender } from '../../types/gender';
import { GeoLocationObject } from '../../types/geolocation';
import { Education } from '../../types/education';
import { EnglishLevel } from '../../types/englishLevel';
import { MilitaryStatus } from '../../types/militaryStatus';
import { RegisterUserResponse } from '../../services/api-response/legacy-response';
import { FormConfig } from '../../components/worker-onboarding/worker-onboarding';

export enum Types {
  PhoneEntered = 'PHONE_ENTERED',
  CountrySelected = 'COUNTRY_SELECTED',
  EmailEntered = 'EMAIL_ENTERED',
  PhoneSubmitted = 'PHONE_SUBMITTED',
  EmailSubmitted = 'EMAIL_SUBMITTED',
  ShowVerification = 'SHOW_VERIFICATION',
  SMSCodeEntered = 'SMS_CODE_ENTERED',
  SMSCodeSubmitted = 'SMS_CODE_SUBMITTED',
  PhoneSubmissionError = 'PHONE_SUBMISSION_ERROR',
  EmailSubmissionError = 'EMAIL_SUBMISSION_ERROR',
  SMSCodeSubmissionError = 'SMS_CODE_SUBMISSION_ERROR',
  ShowEmailCheck = 'SHOW_EMAIL_CHECK',
  ShowRegister = 'SHOW_REGISTER',
  ShowQuestions = 'SHOW_QUESTIONS',
  FirstNameEntered = 'FIRST_NAME_ENTERED',
  LastNameEntered = 'LAST_NAME_ENTERED',
  RegisterSubmitted = 'REGISTER_SUBMITTED',
  ShowVerificationThenNameSurname = 'SHOW_VERIFICATION_THEN_NAME_SURNAME',
  RegisterSubmissionError = 'REGISTER_SUBMISSION_ERROR',
  GenderSelect = 'GENDER_SELECT',
  BirthdateSelect = 'BIRTHDATE_SELECT',
  RegisterCodeError = 'REGISTER_CODE_ERROR',
  LocationSelected = 'LOCATION_SELECTED',
  UserGeolocationChange = 'USER_GEOLOCATION_CHANGE',
  ShowOnboarding = 'SHOW_ONBOARDING',
  EducationSelect = 'EDUCATION_SELECT',
  EnglishLevelSelect = 'ENGLISH_LEVEL_SELECT',
  MilitaryStatusSelect = 'MILITARY_STATUS_SELECT',
  DrivingLicencesSelect = 'DRIVING_LICENCES_SELECT',
  CurrentWorkStatusSelect = 'CURRENT_WORK_STATUS_SELECT',
  WorkTimePreferencesSelect = 'WORK_TIME_PREFERENCES_SELECT',
  ExpectedSalarySelect = 'EXPECTED_SALARY_SELECT',
  PersonalInfosSubmitted = 'PERSONAL_INFOS_SUBMITTED',
  PersonInfosSubmitError = 'PERSONAL_INFOS_SUBMIT_ERROR',
}

export type PhoneEnteredPayload = {
  phoneNumber: string;
};

export type CountrySelectedPayload = {
  country: string;
};

export type EmailEnteredPayload = {
  email: string;
};

export type SMSCodeEnteredPayload = {
  code: string;
};

export type ErrorPayload = {
  error: string;
};

export type NameOrSurnameEnteredPayload = {
  value: string;
};

export type GenderSelectPayload = {
  gender: Gender;
};

export type BirthdateSelectPayload = {
  birthdate: MomentDate;
};

export type LocationSelectedPayload = {
  latLon: { lat: number; lon: number };
};

export type UserGeolocationChangePayload = {
  geolocation: string;
};

export type EducationSelectedPayload = {
  education: Education;
};

export type EnglishLevelSelectedPayload = {
  englishLevel: EnglishLevel;
};

export type MilitaryStatusSelectedPayload = {
  militaryStatus: MilitaryStatus;
};
export type DrivingLicencesSelectedPayload = {
  drivingLicences: string[];
};
export type CurrentWorkStatusSelectedPayload = {
  currentWorkStatus: boolean;
};
export type WorkTimePreferencesSelectedPayload = {
  workTimePreferences: number[];
};
export type ExpectedSalarySelectedPayload = {
  expectedSalary: number;
};
export type ShowOnboardingPayload = {
  result: RegisterUserResponse;
};
export type PersonalInfosSubmittedPayload = {
  formConfig: FormConfig;
};
export type PersonInfosSubmitErrorPayload = {
  error: string;
};
export type EmptyPayload = Record<string, never>;

type UserActionPayload =
  | CountrySelectedPayload
  | PhoneEnteredPayload
  | EmailEnteredPayload
  | EmptyPayload
  | SMSCodeEnteredPayload
  | ErrorPayload
  | GenderSelectPayload
  | BirthdateSelectPayload
  | NameOrSurnameEnteredPayload
  | LocationSelectedPayload
  | UserGeolocationChangePayload
  | EducationSelectedPayload
  | EnglishLevelSelectedPayload
  | MilitaryStatusSelectedPayload
  | DrivingLicencesSelectedPayload
  | CurrentWorkStatusSelectedPayload
  | WorkTimePreferencesSelectedPayload
  | ExpectedSalarySelectedPayload
  | ShowOnboardingPayload
  | PersonalInfosSubmittedPayload;

export type UserState = {
  phoneNumber: string;
  country: string;
  email?: string;
  verificationCode: string;
  loading: boolean;
  showVerification: boolean;
  showEmailCheck: boolean;
  phoneError?: string;
  emailError?: string;
  phoneSubmissionError?: string;
  emailSubmissionError?: string;
  verificationError?: string;
  showRegister: boolean;
  showQuestions: boolean;
  firstName?: string;
  lastName?: string;
  registerSubmissionError?: string;
  isRegistering: boolean;
  registerError?: string;
  fullnameError?: string;
  gender?: Gender;
  birthDate: MomentDate;
  registerCodeError?: string;
  verificationReregister?: boolean;
  latLon?: { lat: number; lon: number };
  geolocation?: GeoLocationObject[] | string;
  showOnboarding: boolean;
  education?: Education;
  englishLevel?: EnglishLevel;
  militaryStatus?: MilitaryStatus;
  drivingLicences?: string[];
  currentWorkStatus?: boolean;
  workTimePreferences?: number[];
  expectedSalary?: number;
  accessToken?: string;
  personalInfosSubmitError?: string;
};

export type UserContext = {
  state: UserState;
  dispatch: Dispatch<UserAction>;
};

export type UserAction = {
  type: Types;
  payload: UserActionPayload;
};
