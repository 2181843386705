import { SxProps, TextField, Theme } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { MomentDate } from '../../types/appointment-slot';
import { getLocale } from '../../utils/common';
import moment from 'moment';
import 'moment/locale/tr';
import 'moment/locale/en-gb';
import 'moment/locale/es-mx';

const locale = getLocale();

export const MuiBirthdate = ({
  onBirthdateSelect,
  initialBirthdate,
  sxProps,
}: {
  onBirthdateSelect: (date: MomentDate) => void;
  initialBirthdate?: MomentDate;
  sxProps?: SxProps<Theme>;
}) => {
  const { t } = useTranslation();
  const [birthdate, setBirthdate] = useState<MomentDate>(initialBirthdate ?? moment(new Date()));

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
      <MobileDatePicker
        label={t('common.birthdate')}
        value={birthdate}
        onChange={(newValue) => {
          setBirthdate(newValue ? newValue : moment(new Date()));
          if (newValue) {
            onBirthdateSelect(newValue);
          }
        }}
        renderInput={(params) => (
          <TextField {...params} fullWidth sx={sxProps} variant="standard" />
        )}
      />
    </LocalizationProvider>
  );
};
