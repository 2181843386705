import { AxiosRequestConfig } from 'axios';
import axiosInstance from '../axios';
import { API_BASE_URL } from '../../constants';
import { Answer } from '../../types/answer';
import { Question } from '../../types/question';
import { checkIfAxiosErrorAndUnauthenticatedAndThrowError, ServiceResponse } from '../common';
import { ANSWER_QUESTION_ENDPOINT, GET_QUESTIONS_BY_JOB_ID_ENDPOINT } from './constants';

export class JobAPI {
  jobServiceRoute = API_BASE_URL;
  config: AxiosRequestConfig = {};

  constructor() {
    this.config = { withCredentials: true };
  }

  getQuestionsByJobIdAndApplicationId = async (
    jobId: number,
    applicationId: number,
  ): Promise<Question[]> => {
    const endpoint = GET_QUESTIONS_BY_JOB_ID_ENDPOINT(jobId);
    const params = new URLSearchParams([['applicationId', applicationId.toString()]]);

    try {
      const result = await axiosInstance.get<ServiceResponse<Question[]>>(
        `${this.jobServiceRoute}/${endpoint}`,
        {
          ...this.config,
          params,
        },
      );

      if (result.data.code === 200) {
        return result.data.payload;
      }
    } catch (e) {
      checkIfAxiosErrorAndUnauthenticatedAndThrowError(e, 'Job');
    }

    throw new Error('common.somethingWentWrong');
  };

  answerQuestion = async (
    questionId: number,
    text: string,
    applicationId: number,
  ): Promise<Answer> => {
    const endpoint = ANSWER_QUESTION_ENDPOINT;

    try {
      const result = await axiosInstance.post<ServiceResponse<Answer>>(
        `${this.jobServiceRoute}/${endpoint}`,
        {
          questionId,
          text,
          applicationId,
        },
        this.config,
      );
      if (result.data.code === 200) {
        return result.data.payload;
      }
    } catch (e) {
      checkIfAxiosErrorAndUnauthenticatedAndThrowError(e, 'Job');
    }

    throw new Error('common.somethingWentWrong');
  };
}
