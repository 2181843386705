import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Job } from '../../types/job';

export const WorkTimePreferencesText = ({ job }: { job: Job }) => {
  const { t } = useTranslation();

  const createText = () => {
    let workTimePreferencesText = '';
    if (job.workTimePreferenceFullTime) {
      workTimePreferencesText += t('constant.fullTime') + ', ';
    }

    if (job.workTimePreferencePartTime) {
      workTimePreferencesText += t('constant.partTime') + ', ';
    }

    if (job.workTimePreferenceSeasonal) {
      workTimePreferencesText += t('constant.seasonal') + ', ';
    }

    if (workTimePreferencesText.slice(-2) === ', ') {
      workTimePreferencesText = workTimePreferencesText.slice(0, -2);
    }

    return workTimePreferencesText;
  };
  return (
    <Typography
      sx={{
        margin: '0.3rem 0',
      }}
      variant="body2">
      {createText()}
    </Typography>
  );
};
